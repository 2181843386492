/* eslint-disable */
import { Col, Flex, Form, Row } from "antd";
import React, { memo, useState } from "react";
import TextInput from "../../../../Components/TextInput/TextInput";
import Component from "../../../../Components";
import Validation from "utils/Validation";
import { useDispatch } from "react-redux";
import { Users } from "../../../../redux/Actions";
import Switch from "../../../../Components/Switch/Switch";
const UserDirectory = ({uDirectoryUpdate,form,UpdateDirectory,visible,onChange})=>{
    const {Button} = Component
    const dispatch = useDispatch();
 
     const validationRules = Validation.getValidation([
        "firstName",
        "lastName",
        "email",
        "city",
        "state",
        "zip",
        "mudEmail",
        "phone",
        "cellPhone"
     
      ]);


  console.log(visible,"visiblevisiblevisiblevisiblevisible")
    return(
        <> 
        <Form  layout="vertical"
          className="ant-form"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }} form={form} onFinish={UpdateDirectory}>
           <Row justify={'space-around'} >
            <Col sm={11}>
            <TextInput name="firstName" label="First Name" placeholder="First Name" rules={validationRules.firstName}
                hasFeedback={true}
                validateTrigger={"onChange"}/>
          
            </Col>
            <Col sm={11}>
            <TextInput name="lastName" label="Last Name" rules={validationRules.lastName}/>
           </Col>
           <Col sm={11}>
            <TextInput name="loginEmail"  label="Login Email" rules={validationRules.email}/>
          </Col>
          <Col sm={11}>
            <TextInput name="mudEmail" label="Email" rules={validationRules.mudEmail}/>
           </Col>
          <Col sm={11}>
            <TextInput name="homePhone" label="Home Phone" rules={validationRules.phone}/>
           </Col>
           <Col sm={11}>
            <TextInput name="cellPhone" label="cell Phone" rules={validationRules.cellPhone}/>
           </Col>
           <Col sm={11}>
            <TextInput name="mudAddress" label="Address" rules={validationRules.Address}/>
           </Col>
           <Col sm={11}>
            <TextInput name="city" label="City" rules={validationRules.city}/>
   </Col>
   <Col sm={11}>
            <TextInput name="state" label="State" rules={validationRules.state}/>
           </Col>
           <Col sm={11}>
           
            <TextInput name="zip" label="Zip" rules={validationRules.zip}/></Col>
            <Col sm={24}>
      <Flex gap={16} align="center" wrap="wrap">
        <Switch name="emailVisible" label="Email Visible" onChange={onChange} value={visible?.emailVisible}/>
        <Switch name="homePhoneVisible" label="Home Phone Visible" onChange={onChange} value={visible?.homePhoneVisible}/>
        <Switch name="cellPhoneVisible" label="Cell Phone Visible" onChange={onChange} value={visible?.cellPhoneVisible}/>
        <Switch name="listingVisible" label="Listing Visible" onChange={onChange} value={visible?.listingVisible}/>
      </Flex>
    </Col>
           </Row>
           <Button  htmlType="submit"
                  title={"Update"}
                  loading={uDirectoryUpdate?.loading}
                  className="create-button"
                  placehoder="First Name" />
        </Form>
        </>
       
    )
};
export default UserDirectory;


