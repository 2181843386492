
import moment from "moment";
import ValidationMessage from "./ValidationConstants";

const cardNumberValidator = (_, value) => {
  // Remove non-digit characters
  const cleanValue = value?.replace(/\D/g, "");

  // Check if the card number is exactly 16 digits
  if (cleanValue !=undefined && cleanValue?.trim()?.length != 0 && cleanValue?.length !== 16) {
    return Promise.reject(new Error("Card number must be 16 digits"));
  }

  return Promise.resolve();
};

class Rules {
  static rules = {
    password: [{ required: true, message: ValidationMessage.PASSWORD }],
    firstName: [{ required: true, message: ValidationMessage.FIRST_NAME }],
    lastName: [{ required: true, message: ValidationMessage.LAST_NAME }],
    residentConatactKey: [{ required: true, message: ValidationMessage.RESIDENT_CONTACT_KEY }],
    residentKey: [{ required: true, message: ValidationMessage.RESIDENT_KEY }],
    phone: [
      { required: true, message: ValidationMessage.PHONE_NUMBER },
      {
        validator: (_, value) => {
          if (!value || value.replace(/\D/g, "").length === 10) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(ValidationMessage.MAXNUMBER));
        },
      },
    ],
   
    gender: [{ required: true, message: ValidationMessage.GENDER }],
    permissionId: [{ required: true, message: ValidationMessage.PERMISSIONS }],
    role: [{ required: true, message: ValidationMessage.ROLE }],
    email: [
      { required: true, message: ValidationMessage.EMAIL },
      { type: "email", message: ValidationMessage.CHECKEmailType },
    ],
    accountNumber: [
      { required: true, message: ValidationMessage.ACCOUNT_NUMBER },
    ],
    communityName: [
      { required: true, message: ValidationMessage.COMMUNITY_NAME },
    ],
    streetAndUnitNumber: [{ required: true, message: ValidationMessage.STREET_AND_UNIT_NUMBER }],
    address:[{ required: true, message: ValidationMessage.STREET_AND_UNIT_NUMBER }],
    communityId: [{ required: true, message: ValidationMessage.COMMUNITY }],
    communities: [{ required: true, message: ValidationMessage.COMMUNITY }],
    units: [{ required: true, message: ValidationMessage.UNITS }],
    hoaName: [{ required: true, message: ValidationMessage.HOA_NAME }],
    zip: [{ required: true, message: ValidationMessage.ZIP }],
    unitName: [{ required: true, message: ValidationMessage.UNIT_NAME }],
    cardHolderName: [
      {
        required: true,
        message: "Cardholder name is required",
      },
      {
        pattern: /^[a-zA-Z\s]*$/,
        message: "Cardholder name should only contain letters and spaces",
      },
    ],
    cardNumber: [
      {
        required: true,
        message: "Card number is required",
      },
      {
        validator: cardNumberValidator,
      },
    ],
    cvv: [
      {
        required: true,
        message: "Input your CVV",
      },
      {
        pattern: /^\d{3,4}$/,
        message: "CVV must be 3 or 4 digits",
      },
    ],
    cardExpiry: [
      {
        required: true,
        message: "Input your expiration date",
      },
      {
        validator: (_, value) => {
          const [month, year] = value ? value.split("/") : ["", ""];
          const currentYear = moment().year() % 100;
          const expirationMonth = parseInt(month, 10);
          const expirationYear = parseInt(year, 10);
          if (
            value != undefined && value?.trim()?.length != 0 &&
            (value?.trim()?.length !== 5 ||
              isNaN(expirationMonth) ||
              expirationMonth < 1 ||
              expirationMonth > 12 ||
              isNaN(expirationYear) ||
              expirationYear < currentYear ||
              (expirationYear === currentYear &&
                expirationMonth < moment().month() + 1))
          ) {
            return Promise.reject(
              new Error("Invalid expiration date or date in the past"),
            );
          }
          return Promise.resolve();
        },
      },
    ],
    accountKey: [{ required: true, message: ValidationMessage.ACCOUNT_KEY }],
    contactNo: [{ required: true, message: ValidationMessage.CONTACT_NO }],
    streetAddress: [{ required: true, message: ValidationMessage.STATE }],
    city: [{ required: true, message: ValidationMessage.CITY }],
    state: [{ required: true, message: ValidationMessage.STATE }],
    zipCode: [{ required: true, message: ValidationMessage.ZIP_CODE }],
    county: [{ required: true, message: ValidationMessage.COUNTY }],
    cCIntegrationKey: [
      { required: true, message: ValidationMessage.CC_INTEGRATION_KEY },
    ],
    cCSetting: [{ required: true, message: ValidationMessage.CC_SETTING }],
    submissionFee: [
      { required: true, message: ValidationMessage.SUBMISSION_FEE },
    ],
    ccFee: [{ required: true, message: ValidationMessage.CC_FEE }],
    mudEmail: [{ required: true, message: ValidationMessage.MUDEMAIL }],
    cellPhone: [{ required: true, message: ValidationMessage.CELL_PHONE },{
      validator: (_, value) => {
        if (!value || value.replace(/\D/g, "").length === 10) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(ValidationMessage.MAXNUMBER));
      },
    },],
  };
}
export default Rules;