import { combineReducers } from 'redux';
import authReducer , {Auth} from './Reducers';
import {Users,Communitys,Headers,Tenants,Disclaimers,RegistrationFees} from './'

const rootReducer = combineReducers({
  auth: authReducer,
  getUser : Auth.detailsReducer,
  GetAllUser :  Users.GetAllUsersListReducer,
  Communitys:Communitys?.GetAllCommunitysListReducer,
  Headers:Headers?.HeadersReducer,
  Tenants:Tenants?.GetAllTenantSubmissionsListReducer,
  Disclaimers:Disclaimers?.GetAllDisclaimersListReducer,
  RegistrationFees:RegistrationFees?.GetAllRegistrationFeesListReducer
});

export default rootReducer;
