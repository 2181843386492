import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select, Modal, Input, Table, Card, Form, Radio, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons'; 
import Spinner from "../../../Components/Spinner/Spiner";
import "../../../assests/styles/base/SubmissionDetails.scss";
import Swal from "sweetalert2";
import UserData from "utils/UserData";
import { Tenants } from "../../../redux/Actions";
import Headers from "../../../redux/Actions/Headers/Header";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import PrefixPath from "../../../config/AppConfig";
import CommonFunctions from "../../../utils/CommonFunction/CommonFunctions";
import moment from "moment";


const formatLocalDate = (date) => {
  if (!date) return null;
  
  const d = new Date(date);

  const localDate = d.toLocaleDateString("en-US", {
    year: "numeric", month: "2-digit", day: "2-digit"
  });
  const localTime = d.toLocaleTimeString("en-US", {
    hour: "2-digit", minute: "2-digit", second: "2-digit"
  });

  return `${localDate} ${localTime}`;
};

const Submissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [form] = Form.useForm()
  const { residentKey } = useParams(); 
  const user = UserData();
  const [submissions, setSubmissions] = useState([]);
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   filter: "",
  //   total: 0,
  //   sort: "DESC",
  //   column:"submissionDate"
  // });
  const [isLoadingPaymentResponse, setIsLoadingPaymentResponse] = useState(false);
  const [isMarkedPaid, setIsMarkedPaid] = useState(false);
  const response = useSelector((state) => state?.Tenants);
  const allSubmissions = response?.getAllTenants;
  const updateTenantRes = response?.updateTenantById;
  const updatePayment = response?.updatePayment;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [remark, setRemark] = useState("");
  const [currentSubmissionId, setCurrentSubmissionId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isPaymentOptionModalOpen, setIsPaymentOptionModalOpen] =
    useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("CC");
    const [isAchPayment, setIsAchPayment] = useState(false);
  const [payaResponse, setPayaResponse] = useState(null);
  const [reasonCodeMessage, setReasonCodeMessage] = useState(false); 
  const [statusMessage, setStatusMessage] = useState(false); 
    const asctohex = (asc) => {
      return Array.from(asc)
        .map((char) => ("0" + char.charCodeAt(0).toString(16)).slice(-2))
        .join("");
    };
    
    const handlePaymentOptionModalCancel = () => {
      setIsPaymentOptionModalOpen(false);
    };
    const handleRadioChange = (e) => {
      setIsAchPayment(e.target.value === "ACH");
      setSelectedPaymentMethod(e.target.value);
    };
    const paymentOptions = [
      { label: "Pay with Credit Card", value: "CC" },
      { label: "Pay with eCheck/ACH", value: "ACH" },
    ];
    const generateUrl = () => {
      // Required parameters
      const params = {
        "developer-id": PrefixPath.DEVELOPER_ID,
        "hash-key": PrefixPath.HASH_KEY,
        "user-id": PrefixPath.USER_ID,
        "timestamp": Math.floor((Date.now() - 60000) / 1000),
        "data": {
          "transaction": {
            "payment_method": isAchPayment ? "ach" : "cc",
            "action": isAchPayment ? "debit" : "sale",
            "transaction_amount": currentRecord?.amount,
            "location_id": PrefixPath.LOCATION_ID,
            "transaction_api_id": uuidv4(),
            "show_cvv": "1",
            "parent_send_message": 1,
            "parent_close": true,
            "product_transaction_id":isAchPayment? PrefixPath.ACH_PRODUCT_TRANSACTION_ID: PrefixPath.CC_PRODUCT_TRANSACTION_ID,
            "ach_sec_code":"WEB",
            "display_close_button": false,
            "order_num": `${currentRecord?.id}`,
            "stylesheet_url":`${PrefixPath.UI_BASE_URL}/style/style.css`
          },
        },
      };
      // [1] Generate the secure hash
      const userHashKey = PrefixPath.HASH_KEY; // Replace with your actual user hash key
      params["hash-key"] = CryptoJS.HmacSHA256(
        params["user-id"] + params["timestamp"],
        userHashKey
      ).toString(CryptoJS.enc.Hex);
  
      // [2] Convert the data to hex
      params["data"] = asctohex(JSON.stringify(params["data"]));
  
      // [3] Build the URL to retrieve the form
      const urlParams = Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
  
      const url = `https://api.sandbox.payaconnect.com/v2/payform?${urlParams}`;
  
      // Log the generated URL for debugging
      console.log("Generated URL:", url);
  
      // Optionally, open the form in a new tab or window
      // window.open(url, "_blank");
      return (
        <>
          <div className={`${!isAchPayment ? "first-frame" : "iframe"}`}>
            <iframe src={url} title="Example iframe"></iframe>
          </div>
        </>
      );
    };
    window.addEventListener("message", receiveMessage, false);
  async function receiveMessage(event) {
    // Make sure the value for allowed matches the domain of the iFrame you are embedding.
    var allowed = "https://api.sandbox.payaconnect.com";
    // Verify sender's identity
    if (event.origin !== allowed) return;
    setIsPaymentOptionModalOpen(false);
    setIsLoadingPaymentResponse(true);

    // Add logic here for doing something in response to the message
    const payaResponse = JSON.parse(event.data);
    setPayaResponse(JSON.parse(event.data))
    console.log(payaResponse);
    
  }
  useEffect(() => {
    if(payaResponse){
      console.log("status_id:",payaResponse?.status_id, "reason_code_id:",payaResponse?.reason_code_id, "type_id:",payaResponse?.type_id, payaResponse?.auth_amount);
      const statusMessage = CommonFunctions.getStatusMessage(payaResponse?.status_id);
      const reasonCodeMessage = CommonFunctions.getReasonCodeMessage(payaResponse?.reason_code_id);
      const typeMessage = CommonFunctions.getTypeMessage(payaResponse?.type_id);
      console.log("reasonCodeMessage:",reasonCodeMessage, "statusMessage:",statusMessage,"typeMessage:",typeMessage)   
    setReasonCodeMessage(reasonCodeMessage);
    setStatusMessage(statusMessage);
    let request = {
      request: {
        PayaTransactionResponse: payaResponse,
        MarkedAsPaid : isMarkedPaid,
        TenantId : currentRecord?.id
      },

    };
    setPayaResponse(null);
        dispatch(Tenants.UpdateTenantPaymentRequest(request))
        }
    
  }, [payaResponse]);

  useEffect(() => {
    if(isMarkedPaid){
    let request = {
      request: {
        PayaTransactionResponse: payaResponse,
        MarkedAsPaid : isMarkedPaid,
        TenantId : currentRecord?.id
      },
    };
    setPayaResponse(null);
        dispatch(Tenants.UpdateTenantPaymentRequest(request))
        }
    
  }, [isMarkedPaid]);

  useEffect(() => {          
    if (updatePayment?.status && (isLoadingPaymentResponse || isMarkedPaid)) {
      // let req = {
      //   request:{
      //     residentKey : "",
      //     searchTerm:form?.getFieldValue('search'),
      //     sortColumn:pagination?.column||'SubmissionDate',
      //     sortOrder:pagination?.sort||'DESC',
      //     pageNumber:pagination?.current,
      //     pageSize:pagination?.pageSize
      //   }
      // }
      dispatch(Tenants.GetAllTenantSubmissionsRequest());

      if(isMarkedPaid){
        Swal.fire({
          title: 'Success',
          text: `Marked As Paid`,
          icon: "success",
          confirmButtonColor: "#5A7890",
        });
        setIsMarkedPaid(false);
      }
       else if(reasonCodeMessage){
          Swal.fire({
                title: `${statusMessage}`,
                text: `Reason: ${reasonCodeMessage}`,
                icon: "error",
                confirmButtonColor: "#5A7890",
              });
        }
        else{
            Swal.fire({
              title: isAchPayment?'ACH - Accepted':'CC - Approved',
              text: isAchPayment?`${statusMessage}`:`Payment Done Successfully.`,
              icon: "success",
              confirmButtonColor: "#5A7890",
            });
        }
    setIsLoadingPaymentResponse(false)
   }
  }, [updatePayment])

  useEffect(() => {
    // let req = {
    //   request:{
    //     residentKey : "",
    //     searchTerm:form?.getFieldValue('search'),
    //     sortColumn:pagination?.column||'SubmissionDate',
    //     sortOrder:pagination?.sort||'DESC',
    //     pageNumber:pagination?.current,
    //     pageSize:pagination?.pageSize
    //   }
    // }
    dispatch(Tenants.GetAllTenantSubmissionsRequest());
  }, []);

  useEffect(() => {
    if (allSubmissions) {
      const transformedData = allSubmissions?.data?.map((item) => ({
        id: item.id,
        property: item.property ?? "",
        submissionDate: item.submissionDate ? formatLocalDate(item.submissionDate) : "",
        submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
        status: item.status === 0 ? "In Process" : item.status === 1 ? "Completed" : "Hold",
        payment: item.paymentStatus === 0 ?  ((item.amount && (item.amount > 0)) ? "Pending": "No Fee") : item.paymentStatus === 1 ? "Done" : item.paymentStatus === 2 ? "Fail" : "Renewal",
        details: item,
        amount: item.amount
      }));
      setSubmissions(transformedData);
      // setPagination({
      //   ...pagination,
      //   total: allSubmissions?.data?.totalCount,
      // });
    }
  }, [allSubmissions]);

  useEffect(() => {
    if (updateTenantRes) {
      if (updateTenantRes?.status === 200) {
        if (updateTenantRes?.data?.status) {
          Swal.fire({
            text: `${currentStatus} Successfully.`,
            icon: "success",
            confirmButtonColor: "#5A7890",
            customClass: {
              title: "text-danger",
              content: "text-danger",
            },
          });
        } else {
          Swal.fire({
            text: updateTenantRes?.data?.message,
            icon: "error",
            confirmButtonColor: "#5A7890",
            customClass: {
              title: "text-danger",
              content: "text-danger",
            },
          });
        }
      }
      
      setIsModalVisible(false);
      setSubmissions(submissions.map((submission) => {
        if (submission.id === currentSubmissionId) {
          submission.status = currentStatus === "Completed" ? "Completed" : "Holded";
          submission.actions = "";
        }
        return submission;
      }));
    }
  }, [updateTenantRes]);
  const { Option } = Select;

  const columns = [
    {
      title: "Property Name",
      dataIndex: "property",
      key: "property",
      sorter: (a, b) => a.property.localeCompare(b.property), // Adding client-side sorting
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Property Name`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.property.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment.utc(a.submissionDate).unix() - moment.utc(b.submissionDate).unix(), // Client-side sorting by timestamp
      sortDirections: ['ascend', 'descend'],
      render: (text) => moment.utc(text).local().format('M/D/YYYY, hh:mm:ss A'),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Submission Date`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => moment.utc(record.submissionDate).local().format('M/D/YYYY, hh:mm:ss A').includes(value),
    },
    {
      title: "Approval",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status), // Sorting by status
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => (
        <Select
          value={text}
          onChange={(value) => handleOk(value, record.id)} 
          style={{ width: 120 }}
        >
          <Option value="Hold">Hold</Option>
          <Option value="Completed">Completed</Option>
          <Option value="In Process">In Process</Option>
        </Select>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="In Process">In Process</Radio>
            <Radio value="Hold">Hold</Radio>
            <Radio value="Completed">Completed</Radio>
          </Radio.Group>
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.status.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      sorter: (a, b) => a.payment.localeCompare(b.payment), // Sorting based on payment value
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="Pending">Pending</Radio>
            <Radio value="No Fee">No Fee</Radio>
            <Radio value="Done">Done</Radio>
            <Radio value="Fail">Fail</Radio>
            <Radio value="Renewal">Renewal</Radio>
          </Radio.Group>
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.payment.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount, // Sorting by amount
      sortDirections: ['ascend', 'descend'],
      render: (text) => (text ? `$${parseFloat(text).toFixed(2)}` : ``),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Amount`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => (record.amount ? record.amount.toString().includes(value) : false),
    },
    {
      title: "Submission Type",
      dataIndex: "submissionType",
      key: "submissionType",
      sorter: (a, b) => a.submissionType.localeCompare(b.submissionType), // Sorting by submissionType
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Submission Type`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.submissionType.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div className="tenant-action-wrap">
          {/* View Details Button */}
          <Button
            style={{
              borderRadius: 19,
              border: "1px solid #fff",
              color: "#fff",
              backgroundColor: "#5A7890",
              height: "2rem",
              width: "7rem",
              marginRight: "10px",
            }}
            onClick={() =>
              navigate(`./View/${residentKey || ""}`, {
                state: { submissionId: record.id },
              })
            }
          >
            View Details
          </Button>
          {/* Show Make a Payment button if residentKey is available */}
          {record.amount && record.payment !== "Done" && record.payment !== "Renewal" && (
            <Button
              style={{
                borderRadius: 19,
                backgroundColor: "#5A7890",
                color: "#fff",
                height: "2rem",
                width: "7rem",
                fontSize: "13px",
              }}
              onClick={() => {
                setCurrentRecord(record);
                setIsMarkedPaid(true);
              }}
            >
              Mark as Paid
            </Button>
          )}
        </div>
      ),
    },
  ];
  
  
  // const handleTableChange = (pagination, filters, sorter) => {
  //   console.log(sorter,"sortersortersortersorter",filters)
  //   const sortOrder = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
  //   const sortColumn = sortOrder ? sorter.field : null;
  //   pagination.sort = sortOrder;
  //   pagination.column = sortColumn;

  //    let req = {
  //     request:{
  //       residentKey : "",
  //       searchTerm:form?.getFieldValue('search') ?? '',
  //       sortColumn:sortColumn||'SubmissionDate',
  //       sortOrder:sortOrder||'DESC',
  //       pageNumber:pagination?.current,
  //       pageSize:pagination?.pageSize
  //     }
  //   }
  //   dispatch(Tenants.GetAllTenantSubmissionsRequest(req));
  //   setPagination(pagination);
  // };
 
  // Show the modal for approving/rejecting
  const showModal = (id, status) => {
    setCurrentSubmissionId(id);
    setCurrentStatus(status);
    setIsModalVisible(true);
  };
  console.log(showModal)

  // Handle modal submit
  const handleOk = (status, recordId) => {
    // if (!remark.trim()) {
    //   alert("Please enter a remark.");
    //   return;
    // }
    // setRemark("");

    const approvalBy = user?.getUserInformation()?.displayName; 
    let req = {
      id: recordId,//currentSubmissionId,
      status : status === "Completed" ? 1 : (status === "Hold" ? 2 : 0), //currentStatus
      reason: "",
      approvalBy: approvalBy
    };
    const request = {
      request:req,
      handle:handleCancel
    };
    dispatch(Tenants.UpdateTenantRequestStatusRequest(request));
   message.success({content:"Status changed successfully."})


    // req = {
    //   request:{
    //     residentKey : "",
    //     searchTerm:form?.getFieldValue('search') ?? '',
    //     sortColumn:'SubmissionDate',
    //     sortOrder:'DESC',
    //     pageNumber:pagination?.current,
    //     pageSize:pagination?.pageSize
    //   }
    // }
    dispatch(Tenants.GetAllTenantSubmissionsRequest());
  };

  // Handle modal cancel
  const handleCancel = () => {
    setIsModalVisible(false);
    setRemark("");
  };
  useEffect(() => {
    dispatch(
      Headers.setHeader({
        title: "Tenant Registration",
        actions: [
        ],
      })
    );
    return () => dispatch(Headers.resetHeader());
  }, [dispatch]);

  return (
    <>
     {(response?.loading || isLoadingPaymentResponse) && <Spinner />}
      <Card>
        <Card>
          <Table columns={columns} scroll={{ x: 'max-content' }} dataSource={submissions}/>
        </Card>
      </Card>

      {/* Modal for remark input */}
      <Modal
        title={`Enter Remark for ${currentStatus}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Input.TextArea
          rows={4}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Please enter your remark"
        />
      </Modal>

      <Modal
        title={`Payment Option`}
        visible={isPaymentOptionModalOpen}
        onOk={() => {}}
        onCancel={handlePaymentOptionModalCancel}
        width={400}
        // height={!isAchPayment ? 50 : 100}
        footer={false}
      >
        <Form>
           <Radio.Group
            value={selectedPaymentMethod}
                    options={paymentOptions}
                    onChange={handleRadioChange}
                    style={{marginBottom:20}}
                  />
                   {isAchPayment && <>
                    
    <p style={{marginBottom:20}}>By authorizing this transaction, customer agrees that merchant may convert this transaction into an Electronic Funds Transfer (EFT) transaction or paper draft, and to debit this account for the amount of the transaction. Additionally, in the event this draft or EFT is returned unpaid, a service fee, as allowable by law, will be charged to this account via EFT or draft. In the event you choose to revoke this authorization, please do so by contacting the merchant directly. Please note that processing times may not allow for revocation of this authorization.</p>
      </>}
          {generateUrl()}
        </Form>
      </Modal>
    </>
  );
};

export default Submissions;
