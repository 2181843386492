/* eslint-disable */
import { Button, List, Tabs } from "antd";
import { ReactElement } from "react";
import ContactInfo from "./ContactInfo";
import MailArhive from "./MailArhive";
import AccountDetail from "./AccountDetail";
import Architectural from "./Architectural";
import Compliances from "./Compliance/Compliances";
import * as React from "react";
import { useStore } from "../../../stores/root-store-context";
import Notes from "./Notes/Notes";
import { observer } from "mobx-react-lite";
import LotDocuments from "./LotDocuments";
import PropertyFaqs from "./Faqs/PropertyFaqs";
const TableAccountInfo = observer((): ReactElement => {
  const { ricStore } = useStore();
  const [lotDocumentsUrl, setLotDocumentsUrl] = React.useState('');

  React.useEffect(() => {
    const fetchUrl = async () => {
      const url = await ricStore.getUlrLotDocument(
        ricStore.accountInformation?.accountNumber
      );
      setLotDocumentsUrl(url);
    };
    
    fetchUrl();
  }, []); // Runs once when the component mounts
console.log(ricStore.contactInformation,"ricStore.contactInformationricStore.contactInformation")
  return (
    <Tabs
      type="card"
      items={[
        {
          label: "Contact Info",
          key: "Contact Info",
          children: (
               <div className="mt-4">
                 <ContactInfo
                  contactInfo={ricStore.contactInformation}
                />
               </div>
          ),
        },
        {
          label: "Account Detail",
          key: "Account Detail",
          children: <AccountDetail dataSource={ricStore.accountDetails} />,
        },
        {
          label: "Notes",
          key: "Notes",
          children: <Notes />,
        },
        {
          label: "Compliance Issues",
          key: "Compliance",
          children: <Compliances dataSource={ricStore.complianceInformation} />,
        },
        {
          label: "Architectural",
          key: "Architectural",
          children: (
            <Architectural
              architecturalData={ricStore.architecturalInformation}
            />
          ),
        },
        {
          label: "Mail Archive",
          key: "Mail Archive",
          children: (
            <MailArhive
              mailItems={ricStore.mailArchive}
              openFileCallback={(dataRecordId) => {
                return ricStore.getPdfByDataRecordId(dataRecordId);
              }}
            />
          ),
        },
        {
          label: "Lot Documents",
          key: "Lot Documents",
          children: (
            <div>
              <LotDocuments />
            </div>
          ),
        },
        {
          label: " Property FAQs",
          key: " Property FAQs",
          children: (
            <div>
            <PropertyFaqs companyKey={ ricStore.accountInformation?.companyKey}/>
            </div>
          ),
        
        }
      ]}
    />
  );
});
export default TableAccountInfo;
