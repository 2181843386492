import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const UnAuthorized = () => {
    const navigate = useNavigate()
    const Logout = ()=>{
        localStorage.removeItem("userData");
    localStorage.removeItem("auth_token");
    navigate("/login")
    }
  return (
    <div style={{ 
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      height: "100vh", 
      width: "100%" 
    }}>
      <Button onClick={Logout} type="primary" size="large">Try Again</Button>
    </div>
  );
};

export default UnAuthorized;