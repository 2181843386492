/* eslint-disable */
/* eslint-disable no-async-promise-executor */
import { makeAutoObservable, runInAction } from "mobx";
import ApiStore from "./ApiStore";
import { RicFilterList } from "../components/RIC/components/Search/SearchForm";
import { AccountInformation } from "../components/RIC/components/AccountInfo/AccountInfoCard";
import { ContactInformation, PhoneData } from "../components/RIC/components/ContactInfo";
import { AccountDetailItem } from "../components/RIC/components/AccountDetail";
import { MailItem } from "../components/RIC/components/MailArhive";
import { ArchitecturalData } from "../components/RIC/components/Architectural";
import { ComplianceItem } from "../components/RIC/components/Compliance/Compliance";
import { message } from "antd";
import dayjs from "dayjs";
import { NoteType } from "../components/RIC/components/Notes/Note";
import {
  EStatementModalData,
  EStatementSignData,
  UnitInfoForAddContact,
} from "../api/RicApi";
import { UserEmployee } from "../api/base/ConfigApi";
import { ISearch, ISearchItem } from "../components/RIC/components/AdvancedSearch";
import { AxiosResponse } from "axios";

export type Account = {
  id: number;
  lastName: string;
  ownerType: string;
  residentKey: string;
  strSearchResult: string;
  unitAddress: string;
};
export type LotDocuments = {
    CommunityId: string;
    Office: string;
    filters: string[];
    searchText: string | null;
}
export type GetFile = {
    CommunityId: string;
    Office: string;
    FilePath: string;
}
export type LotDocumentItem = {
  filePath: string | null,
  localName: string | null
}
export type AddContactInfoResponse = {
  AccountNumber?: string;
  Email?: string | null;
  LastName?: string;
  FirstName?: string;
  ExpirationDate?: string;
  DateReceived?: string;
  Birthdate?: string;
  Note?: string;
  Occupancy?: boolean;
  User?: string;
  EmployeeKey?: string;
  Phones?: any[];
  ModifiedString?: string;
  ContactAddress?: {
    ReferenceID: string;
    MailingName: string;
    MailingName2: string;
    MailingSalutation: string;
    MailingAddress1: string;
    MailingAddress2: string;
    MailingCity: string;
    MailingRegion: string;
    MailingCountry: string;
    MailingPostalCode: string;
    UnitKey: string;
  };
};

export enum TypeAddModal {
  AddContact,
  AddRentedContact,
  AddFeatureContact,
}

export enum EStatement {
  Paper,
  Electronic,
}

class RicStore {
  private _api: ApiStore;

  public isLoadingApp: boolean = false;

  public isLoadingRicData: boolean = false;
  public isLoadingAllSearch: boolean = false;
  public filterList: RicFilterList = {
    accounts: [],
    addressSearch: [],
    namesSearch: [],
    eTrakAccounts: [],
  };
  public lotDocuments : LotDocuments[] = [];
  public accountInformation!: AccountInformation;
  public contactInformation: ContactInformation[] = [];
  public contactPhoneLegth : PhoneData[] = []
  public notesInformation: NoteType[] = [];
  public category1Items: string[] = [];
  public typeItems: string[] = [];
  public accountDetails: AccountDetailItem[] = [];
  public mailArchive: MailItem[] = [];
  public architecturalInformation: ArchitecturalData[] = [];
  public complianceInformation: ComplianceItem[] = [];
  public selectedAccount: number | string | undefined = undefined;
  public isShowRicInfo: boolean = false;
  public isEditable: boolean = false;
  public isLoadLoaderZEGO: boolean = false;
  public isLoadAccountInfo: boolean = false;
  public searchAllAccounts: Account[] = [];
  public accountList : [];
  public accountCount? : number;
  public eStatementData: EStatementModalData | undefined = undefined;
  public eStatementLoading: boolean = false;
  public eStatementRadio: EStatement = EStatement.Paper;
  public eStatementMessage: string = "";

  public isUploadFile: boolean = false;

  constructor(api: ApiStore, isEditable: boolean) {
    makeAutoObservable(this);
    this._api = api;
    this.isEditable = isEditable;
  }
  public deactivateAccountRenter = (): Promise<boolean> => {
    this.isLoadingApp = true;
    const numberIsString =
      typeof this.accountInformation.accountNumber === "string";
    const accNumber = numberIsString
      ? this.accountInformation.accountNumber
          .trim()
          .split(" ")[0]
          .trim()
          .split("-")[0]
          .trim()
          .split("_")[0]
          .trim()
      : this.accountInformation.accountNumber;
    return this._api.RicApiClient.deactivateRenter(accNumber)
      .then((result) => {
        this.isLoadingApp = false;
        this.setShowRicInfo(false);
        this.setAccount(undefined);
        return true;
      })
      .catch((error) => {
        this.isLoadingApp = false;
        console.log(error);
        return false;
      });
  };
  public getFilterList = (id: number) => {
    this.isLoadingApp = true;
    this._api.RicApiClient.getSearchFilters(id)
      .then((result) => {
        this.isLoadingApp = false;
        this.filterList = {
          accounts: result.data["accounts"],
          addressSearch: result.data["addresSearch"],
          eTrakAccounts: result.data["eTrakAccounts"],
          namesSearch: result.data["namesSearch"],
        } as RicFilterList;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
      });
  };
  public getLotDocuments = (lotDocuments:LotDocuments):Promise<boolean>=>{
    return this._api.RicApiClient.getLotDocuments(lotDocuments ).then((result) => {
    if(result?.status){
      this.lotDocuments = result?.data;
    }

        this.isLoadingApp = false;
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
        return false;
      });
  }
  public getFile = (getFile:GetFile):Promise<any>=>{
    debugger;
        return this._api.RicApiClient.getFile(getFile ).then((result) => {
        if(result?.status){
          return result?.data;
        }

        this.isLoadingApp = false;
        return undefined
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
        return undefined;
      });
  }

  
  public deleteAccountContactInfo = (
    methodId: string,
    str: string,
    accountNumber: string,
    currentEmployeeKey: string
  ): Promise<boolean> => {
    return this._api.RicApiClient.deleteContactInfo(
      methodId,
      str,
      accountNumber,
      currentEmployeeKey
    )
      .then((result) => {
        this.isLoadingApp = false;
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
        return false;
      });
  };

  public deleteAccountContactItem = (
    contactInfo: ContactInformation,
    employeeKey: string,
    setLoading: (status: boolean) => void
  ): Promise<boolean> => {
    const deleteData = {
      ResidentContactId: contactInfo.contactId,
      accountNumber: contactInfo.accountNumber,
      ModifiedString: `Contact '${contactInfo.firstName} ${contactInfo.lastName}' was deleted for ContactId - '${contactInfo.contactId}'`,
      EmployeeKey: employeeKey,
    };
    return this._api.RicApiClient.deleteContactItem(deleteData)
      .then(async (result) => {
        await this.getContactInformation(
          this.selectedAccount?.toString() || "",
          setLoading
        );
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  public updateAccount = (
    contactInfo: ContactInformation,
    currentEmployeeKey: string,
    currentuser: string
  ): Promise<boolean> => {
    this.isLoadingApp = true;
    const formData = new FormData();
    formData.append("EmployeeKey", currentEmployeeKey);
    formData.append("user", currentuser.toString());
    formData.append("accountNumber", contactInfo.accountNumber);
    formData.append("id", contactInfo.id);
    if (contactInfo.emailMethodId !== null) {
      formData.append("emailMethodId", contactInfo.emailMethodId?.toString());
    }
    if (contactInfo.email !== null) {
      formData.append("email", contactInfo.email);
    }
    if (contactInfo.note !== null) {
      formData.append("note", contactInfo.note);
    }
    if (contactInfo.dateReceived !== null) {
      formData.append(
        "dateReceived",
        dayjs(contactInfo.dateReceived).format("M/D/YYYY")
      );
    }
    if (contactInfo.expirationDate !== null) {
      formData.append(
        "expirationDate",
        dayjs(contactInfo.expirationDate).format("M/D/YYYY")
      );
    }
    if (contactInfo.birthdate !== null) {
      formData.append(
        "birthdate",
        dayjs(contactInfo.birthdate).format("M/D/YYYY")
      );
    }
    if (contactInfo.modifiedString !== null) {
      formData.append("ModifiedString", contactInfo.modifiedString);
    }
    contactInfo.phones?.map((el, index) => {
      formData.append(`Phones[${index}].MethodId`, el.methodId.toString());
      formData.append(`Phones[${index}].Type`, el.type.toString());
      formData.append(`Phones[${index}].Number`, el.number || "");
    });
    return this._api.RicApiClient.saveContactInfo(contactInfo.id, formData)
      .then((result) => {
        this.isLoadingApp = false;
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
        return false;
      });
  };

  public updateNameAndAddress = (
    contactInfo: ContactInformation,
    currentEmployeeKey: string,
    currentuser: string,
    modifiedString?: string
  ): Promise<boolean> => {
    this.isLoadingApp = true;
    const formData = new FormData();
    formData.append("EmployeeKey", currentEmployeeKey);
    formData.append("user", currentuser.toString());
    formData.append("accountNumber", contactInfo.accountNumber);
    if(contactInfo.id){
      formData.append("id", contactInfo.id);
    }
   
    if (contactInfo.emailMethodId != null) {
      formData.append("emailMethodId", contactInfo.emailMethodId?.toString());
    }
    if (contactInfo.email) {
      formData.append("email", contactInfo.email);
    }
    if (contactInfo.note) {
      formData.append("note", contactInfo.note);
    }
    if (contactInfo.dateReceived) {
      formData.append(
        "dateReceived",
        dayjs(contactInfo.dateReceived).format("M/D/YYYY")
      );
    }
    if (contactInfo.expirationDate) {
      formData.append(
        "expirationDate",
        dayjs(contactInfo.expirationDate).format("M/D/YYYY")
      );
    }
    if (contactInfo.birthdate) {
      formData.append(
        "birthdate",
        dayjs(contactInfo.birthdate).format("M/D/YYYY")
      );
    }
    if (contactInfo.modifiedString) {
      formData.append("ModifiedString", contactInfo.modifiedString);
    }
    console.log(contactInfo,"contactInfo.phonescontactInfo.phonescontactInfo.phones")
    contactInfo.phones?.map((el, index) => {
      formData.append(`Phones[${index}].MethodId`, el.methodId.toString());
      formData.append(`Phones[${index}].Type`, el.type.toString());
      formData.append(`Phones[${index}].Number`, el.number || "");
    });
    if (contactInfo.firstName !== null) {
      formData.append("firstName", contactInfo.firstName || "");
    }

    if (contactInfo.lastName !== null) {
      formData.append("lastName", contactInfo.lastName || "");
    }

    if (contactInfo.contactAddress?.mailingAddress1 !== null) {
      formData.append(
        "contactAddress.mailingAddress1",
        contactInfo.contactAddress?.mailingAddress1 || ""
      );
    }
    if (contactInfo.contactAddress?.mailingAddress2 !== null) {
      formData.append(
        "contactAddress.mailingAddress2",
        contactInfo.contactAddress?.mailingAddress2 || ""
      );
    }
    if (contactInfo.contactAddress?.mailingCity !== null) {
      formData.append(
        "contactAddress.mailingCity",
        contactInfo.contactAddress?.mailingCity || ""
      );
    }
    if (contactInfo.contactAddress?.mailingRegion !== null) {
      formData.append(
        "contactAddress.mailingRegion",
        contactInfo.contactAddress?.mailingRegion || ""
      );
    }
    if (contactInfo.contactAddress?.mailingPostalCode !== null) {
      formData.append(
        "contactAddress.mailingPostalCode",
        contactInfo.contactAddress?.mailingPostalCode || ""
      );
    }
    if (modifiedString && modifiedString.length > 0) {
      formData.append("ModifiedString", modifiedString);
    }

    return this._api.RicApiClient.saveContactInfo(contactInfo.id, formData)
      .then((result) => {
        this.isLoadingApp = false;
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingApp = false;
        return false;
      });
  };
  public preparateNote = (note: any): FormData => {
    const newNote = new FormData();
    newNote.append("CloseEmployeeKey", note.closeEmployeeKey);
    newNote.append("ResponsibleEmployeeKey", note.responsibleEmployeeKey);
    newNote.append("Contact", note.contact);
    newNote.append("Created", note.created);
    newNote.append("FileName", note.fileName);
    newNote.append("OnWeb", note.onWeb);
    newNote.append("OpenEmployeeKey", note.openEmployeeKey);
    newNote.append("Type", note.Type);
    newNote.append("accountNumber", note.accountNumber);
    newNote.append("NoteId", note.NoteID);
    newNote.append("Resolution", note.resolution);
    newNote.append("closedStr", note.closed.format("YYYY-MM-DD"));
    newNote.append("Type", note.Type);
    newNote.append("category1", note.category1);
    newNote.append("noteText", note.noteText);
    newNote.append("binaryFile", note.binaryFile); //
    newNote.append("resolution", note.resolution);
    return newNote;
  };

  public updateNote = (
    note: NoteType,
    setLoading: (status: boolean) => void
  ): Promise<boolean> => {
    setLoading(true);
    const newData = this.preparateNote(note);
    return this._api.RicApiClient.saveNote(newData)
      .then((result) => {
        this.notesInformation =
          result.data?.notes.map((el: any) => {
            const note: NoteType = el;
            note.closed = dayjs(note.closed);
            note.opened = dayjs(note.opened);
            return note;
          }) || [];
        this.category1Items = result.data?.category1Items || [];
        this.typeItems = result.data?.typeItems || [];
        setLoading(false);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  public updateFileNote = (
    note: NoteType,
    setLoading: (status: boolean) => void
  ): Promise<string> => {
    this.isUploadFile = true;
    setLoading(true);
    const newData = this.preparateNote(note);
    return this._api.RicApiClient.saveNote(newData)
      .then((result) => {
        this.notesInformation =
          result.data?.notes.map((el: any) => {
            const note: NoteType = el;
            note.closed = dayjs(note.closed);
            note.opened = dayjs(note.opened);
            return note;
          }) || [];
        this.category1Items = result.data?.category1Items || [];
        this.typeItems = result.data?.typeItems || [];
        setLoading(false);
        this.isUploadFile = false;
        return (
          this.notesInformation.find((el) => el.NoteID === note.NoteID)
            ?.fileUrl || ""
        );
      })
      .catch((error) => {
        console.log(error);
        this.isUploadFile = false;
        return "";
      });
  };

  public createNote = (
    accountNumber: string,
    openEmployeeKey: string,
    setLoading: (status: boolean) => void
  ): Promise<boolean> => {
    const data = new FormData();
    data.append("accountNumber", accountNumber);
    data.append("openEmployeeKey", openEmployeeKey);
    setLoading(true);
    return this._api.RicApiClient.saveNote(data)
      .then((result) => {
        this.notesInformation =
          result.data?.notes.map((el: any) => {
            const note: NoteType = el;
            note.closed = dayjs(note.closed);
            note.opened = dayjs(note.opened);
            return note;
          }) || [];
        this.notesInformation[0].isNew = true;
        this.category1Items = result.data?.category1Items || [];
        this.typeItems = result.data?.typeItems || [];
        setLoading(false);
        return true;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        return false;
      });
  };

  public getAllSearchList = (text: string): void => {
    this.isLoadingAllSearch = true;
    this._api.RicApiClient.getSearchAccounts(text)
      .then((result) => {
        this.searchAllAccounts = result.data || [];
        this.isLoadingAllSearch = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoadingAllSearch = false;
      });
  };

  public setAccount = (account: number | string | undefined) => {
    this.selectedAccount = account;
  };
public SetPhoneLength = (contactPhone : PhoneData[])=>{
  this.contactPhoneLegth = contactPhone
}
  public setIsLoadingApp = (status: boolean) => {
    this.isLoadingApp = status;
  };

  public setShowRicInfo = (status: boolean) => {
    this.isShowRicInfo = status;
  };

  public getPdfByDataRecordId = async (
    dataRecordId: number
  ): Promise<string> => {
    const respose = await this._api.RicApiClient.getPdfByDataRecordId(
      dataRecordId
    );
    if (respose.status === 200) {
      return respose.data;
    } else {
      return `Error ${JSON.stringify(respose, undefined, 2)}`;
    }
  };

  public getUlrLotDocument = async (accountNumber: string): Promise<string> => {
    let numberIsString = typeof accountNumber == "string";
    const accNumber = `=${
      numberIsString
        ? accountNumber
            .trim()
            .split(" ")[0]
            .trim()
            .split("-")[0]
            .trim()
            .split("_")[0]
            .trim()
        : accountNumber
    }`;
    return await this._api.RicApiClient.getLotDocumentGetProperies(accNumber)
      .then((response) => {
        return response.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  public getPropertyFaq = async (companyKey: string): Promise<string> => {

    return await this._api.RicApiClient.getPropertyFaq(companyKey)
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  public async fetchAccountInformation(accountId: string): Promise<void> {
    this.setAccount(accountId);
  }

  public async fetchIsAccessToProperty(accountId: string): Promise<void> {
    this.accountInformation = {
      ...this.accountInformation,
      loading: true,
      autocompleteLoading: false,
      autocompleteOpen: false,
      textAccountNumber: accountId,
      accountNumber: accountId,
      isAccessToProperty: true,
      propertyName: "",
      companyKey:
        this.accountInformation.selectedProperty &&
        this.accountInformation.selectedProperty[0].id,
    };
    await this.fetchAccountInformation(accountId);
  }

  public toMainAcc = (): void => {
    this.accountInformation.isMainAcc = true;
    this.fetchIsAccessToProperty(this.accountInformation.mainAccNumb).catch(
      console.log
    );
  };

  public accesAccountFuture = (): void => {
    this.accountInformation.isMainAcc = false;
    this.fetchIsAccessToProperty(
      this.accountInformation.activeFutures[0].residentKey
    ).catch(console.log);
  };

  public accesAccountRenter = (): void => {
    this.accountInformation.isMainAcc = false;
    this.fetchIsAccessToProperty(
      this.accountInformation.activeRenters[0].residentKey
    ).catch(console.log);
  };

  public updateContactInformation = (
    updatedContactInformation: ContactInformation[]
  ): void => {
    this.contactInformation = updatedContactInformation;
  };

  public getContactInformation = async (
    selectedAccount: string,
    setLoading?: (status: boolean) => void
  ): Promise<void> => {
    runInAction(() => {
      const contactInformationPromise = new Promise(async (resolve, reject) => {
        try {
          const getData = await this._api.RicApiClient.getContactInformation(
            selectedAccount
          );
          if (getData.status === 200) {
            this.contactInformation = getData.data || [];
            resolve({ contactInformation: getData.data });
          } else {
            this.contactInformation = [];
            reject(
              "Contact Information don`t load :" +
                JSON.stringify(getData, undefined, 2)
            );
          }
        } catch (error) {
          this.contactInformation = [];
          reject(
            "Contact Information don`t load :" +
              JSON.stringify(error, undefined, 2)
          );
        }
      });
      Promise.all([contactInformationPromise])
        .then(() => {
          if (setLoading) setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (setLoading) setLoading(false);
        });
    });
  };

  public resetRicInfo = (): void => {
    this.contactInformation = [];
    this.accountInformation = {} as AccountInformation;
    this.complianceInformation = [];
    this.notesInformation = [];
    this.category1Items = [];
    this.typeItems = [];
    this.architecturalInformation = [];
    this.accountDetails = [];
    this.mailArchive = [];
  };

  public getRicInfo = async (): Promise<void> => {
    if (this.selectedAccount) {
      const account = this.selectedAccount;
      this.isLoadingApp = true;
      this.isLoadingRicData = true;
      this.isShowRicInfo = false;
      runInAction(() => {
        const contactInformationPromise = new Promise(
          async (resolve, reject) => {
            try {
              const getData =
                await this._api.RicApiClient.getContactInformation(account);
              if (getData.status === 200) {
                
                this.contactInformation = getData.data || [];
                resolve({ contactInformation: getData.data });
              } else {
                this.contactInformation = [];
                reject(
                  "Contact Information don`t load :" +
                    JSON.stringify(getData, undefined, 2)
                );
              }
            } catch (error) {
              this.contactInformation = [];
              reject(
                "Contact Information don`t load :" +
                  JSON.stringify(error, undefined, 2)
              );
            }
          }
        );
        const accountInformationPromise = new Promise(
          async (resolve, reject) => {
            try {
              const getData =
                await this._api.RicApiClient.getAccountInformation(account);
              if (getData.status === 200) {
                this.accountInformation = {
                  ...getData.data,
                  isMainAcc: getData.data.isMain,
                  futuresAccNumbers: getData.data.futures,
                  mainAccNumb: getData.data.mainAcc,
                };
                resolve({ accountInformation: getData.data });
              } else {
                this.accountInformation = {} as AccountInformation;
                reject(
                  "Account Information don`t load :" +
                    JSON.stringify(getData, undefined, 2)
                );
              }
            } catch (error) {
              this.accountInformation = {} as AccountInformation;
              reject(
                "Account Information don`t load :" +
                  JSON.stringify(error, undefined, 2)
              );
            }
          }
        );
        const complianceInformationPromise = new Promise(
          async (resolve, reject) => {
            try {
              const getData =
                await this._api.RicApiClient.getComplianceInformation(account);
              if (getData.status === 200) {
                this.complianceInformation = getData.data || [];
                resolve({ complianceInformation: getData.data });
              } else {
                this.complianceInformation = [];
                reject(
                  "Compliance Information don`t load :" +
                    JSON.stringify(getData, undefined, 2)
                );
              }
            } catch (error) {
              this.complianceInformation = [];
              reject(
                "Compliance Information don`t load :" +
                  JSON.stringify(error, undefined, 2)
              );
            }
          }
        );
        const notesInformationPromise = new Promise(async (resolve, reject) => {
          try {
            const getData = await this._api.RicApiClient.getNotesInformation(
              account
            );
            if (getData.status === 200) {
              this.notesInformation =
                getData.data?.notes.map((el: any) => {
                  const note: NoteType = el;
                  note.closed = dayjs(note.closed);
                  note.opened = dayjs(note.opened);
                  return note;
                }) || [];
              this.category1Items = getData.data?.category1Items || [];
              this.typeItems = getData.data?.typeItems || [];
              resolve({ notesInformation: getData.data });
            } else {
              reject(
                "Notes Information don`t load :" +
                  JSON.stringify(getData, undefined, 2)
              );
            }
          } catch (error) {
            reject(
              "Notes Information don`t load :" +
                JSON.stringify(error, undefined, 2)
            );
          }
        });
        // const nordisInformationPromise = new Promise(async (resolve, reject) => {
        //     try {
        //         const getData = await this._api.RicApiClient.getNordisInformation(account);
        //         if (getData.status === 200) {
        //             resolve({ nordisInformation: getData.data })
        //         } else {
        //             reject("Nordis Information don`t load :" + JSON.stringify(getData, undefined, 2))
        //         }
        //     } catch (error) {
        //         reject("Nordis Information don`t load :" + JSON.stringify(error, undefined, 2))
        //     }
        // });
        const architecturalInformationPromise = new Promise(
          async (resolve, reject) => {
            try {
              const getData =
                await this._api.RicApiClient.getArchitecturalInformation(
                  account
                );
              if (getData.status === 200) {
                this.architecturalInformation = getData.data || [];
                resolve({ architecturalInformation: getData.data });
              } else {
                this.architecturalInformation = [];
                reject(
                  "Architectura lInformation don`t load :" +
                    JSON.stringify(getData, undefined, 2)
                );
              }
            } catch (error) {
              this.architecturalInformation = [];
              reject(
                "ArchitecturalInformation don`t load :" +
                  JSON.stringify(error, undefined, 2)
              );
            }
          }
        );
        const accountDetailPromise = new Promise(async (resolve, reject) => {
          try {
            const getData = await this._api.RicApiClient.getAccountDetail(
              account
            );
            if (getData.status === 200) {
              this.accountDetails = getData.data || [];
              resolve({ accountDetail: getData.data });
            } else {
              this.accountDetails = [];
              reject(
                "AccountDetail don`t load :" +
                  JSON.stringify(getData, undefined, 2)
              );
            }
          } catch (error) {
            this.accountDetails = [];
            reject(
              "AccountDetail don`t load :" + JSON.stringify(error, undefined, 2)
            );
          }
        });
        const mailArchivePromise = new Promise(async (resolve, reject) => {
          try {
            const getData = await this._api.RicApiClient.getMailArchive(
              account
            );
            if (getData.status === 200) {
              this.mailArchive = getData.data || [];
              resolve({ mailArchive: getData.data });
            } else {
              reject(
                "MailArchive don`t load :" +
                  JSON.stringify(getData, undefined, 2)
              );
            }
          } catch (error) {
            reject(
              "MailArchive don`t load :" + JSON.stringify(error, undefined, 2)
            );
          }
        });

        const getAllDataPromise = [
          contactInformationPromise,
          accountInformationPromise,
          complianceInformationPromise,
          architecturalInformationPromise,
          accountDetailPromise,
          mailArchivePromise,
          notesInformationPromise,
        ];

        Promise.all(getAllDataPromise)
          .then((allData: any[]) => {
            this.isLoadingApp = false;
            this.isLoadingRicData = false;
            this.isShowRicInfo = true;
          })
          .catch(async (err) => {
            this.isLoadingApp = false;
            this.isLoadingRicData = false;
            this.isShowRicInfo = true;
            const index = err.indexOf(":");
            const errorName = index !== -1 ? err.slice(0, index) : err;
            console.log(err);
            await message.error(errorName);
          });
      });
    } else {
      this.accountInformation = {} as AccountInformation;
      this.contactInformation = [];
      this.accountDetails = [];
      this.mailArchive = [];
      this.architecturalInformation = [];
      this.complianceInformation = [];
    }
  };

  public getUnitInfoForAddContact =
    async (): Promise<UnitInfoForAddContact> => {
      const result = await this._api.RicApiClient.getUnitInfoForAddContact(
        this.accountInformation?.accountNumber
      );
      return { ...result.data } as UnitInfoForAddContact;
    };

  public AddContact = async (
    data: AddContactInfoResponse,
    type: TypeAddModal
  ): Promise<boolean> => {
    if (type === TypeAddModal.AddContact) {
      const result = await this._api.RicApiClient.saveContact(data);
      return result.data > 0;
    } else if (type === TypeAddModal.AddFeatureContact) {
      const result = await this._api.RicApiClient.saveFutureOwnerAccount(data);
      return result.data > 0;
    } else if (type === TypeAddModal.AddRentedContact) {
      const result = await this._api.RicApiClient.saveRenterAccount(data);
      return result.data > 0;
    }
    return false;
  };

  public searchAccountInformation = async (
    itemSearch: ISearchItem
  ): Promise<AxiosResponse> => {
    return this._api.RicApiClient.searchAccountInformation(itemSearch);
  };
  public searchInformation = async (
    itemSearch: ISearch
  ): Promise<AxiosResponse> => {
    return this._api.RicApiClient.searchInformation(itemSearch);
  };

  public setIsLoadLoaderZEGO = (status: boolean): void => {
    this.isLoadLoaderZEGO = status;
  };

  public setAccountList = (list:[]): void => {
    this.accountList = list;
  }
  public setAccountListCount = (count:number): void => {
    this.accountCount = count;
  }
  public setIsLoadAccountInfo = (status: boolean): void => {
    this.isLoadAccountInfo = status;
  };

  public createPdf = (): void => {
    const result =
      this.contactInformation === null ||
      this.contactInformation === undefined ||
      this.contactInformation.length === 0
        ? null
        : this.contactInformation.find((a) => a.type === "Owner");

    if (result !== null && result !== undefined) {
      const data = {
        FirstName: result.firstName,
        LastName: result.lastName,
        AccountNumber: result.accountNumber,
        Email:
          result.email === null || result.email === undefined
            ? ""
            : result.email,
      };
      this.setIsLoadLoaderZEGO(true);
      this._api.RicApiClient.createPdfWithContacInfo(data)
        .then((resp: { data: { data: string } }) => {
          if (resp) {
            const binaryString = window.atob(resp.data.data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            const blob = new Blob([bytes], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const fileName = "ZEGO_Pay_Now_Info";
            link.download = fileName;
            link.click();
            this.setIsLoadLoaderZEGO(false);
          }
        })
        .catch((error) => {
          this.setIsLoadLoaderZEGO(false);
          console.log("error", error);
        });
    }
  };

  public createNewPdfFinancials = (): void => {
    this.setIsLoadAccountInfo(true);
    this._api.RicApiClient.сreateNewPdfFinancials(
      this.accountInformation.accountNumber
    )
      .then((resp: { data: { data: string } }) => {
        if (resp) {
          const binaryString = atob(resp.data.data);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          const blob = new Blob([bytes], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const fileName = "Account Detail";
          link.download = fileName;
          link.click();
          this.setIsLoadAccountInfo(false);
        }
      })
      .catch((error) => {
        this.setIsLoadAccountInfo(false);
        console.log("error", error);
      });
  };

  public getNotes = (): void => {
    const notesInformationPromise = new Promise(async (resolve, reject) => {
      try {
        const getData = await this._api.RicApiClient.getNotesInformation(
          this.accountInformation.accountNumber
        );
        if (getData.status === 200) {
          this.notesInformation =
            getData.data?.notes.map((el: any) => {
              const note: NoteType = el;
              note.closed = dayjs(note.closed);
              note.opened = dayjs(note.opened);
              return note;
            }) || [];
          this.category1Items = getData.data?.category1Items || [];
          this.typeItems = getData.data?.typeItems || [];
          resolve({ notesInformation: getData.data });
        } else {
          reject(
            "Notes Information don`t load :" +
              JSON.stringify(getData, undefined, 2)
          );
        }
      } catch (error) {
        reject(
          "Notes Information don`t load :" + JSON.stringify(error, undefined, 2)
        );
      }
    });

    const getAllDataPromise = [notesInformationPromise];

    Promise.all(getAllDataPromise)
      .then((allData: any[]) => {
        this.isLoadingApp = false;
        this.isShowRicInfo = true;
      })
      .catch(async (err) => {
        this.isLoadingApp = false;
        this.isShowRicInfo = true;
        const index = err.indexOf(":");
        const errorName = index !== -1 ? err.slice(0, index) : err;
        console.log(err);
        await message.error(errorName);
      });
  };

  public loadEStatement = async (): Promise<void> => {
    this.eStatementData = undefined;
    this.eStatementLoading = true;
    const eStatementData = await this._api.RicApiClient.getEStatementSign(
      this.accountInformation.accountNumber
    );
    console.log(eStatementData.data);
    this.eStatementData = { ...eStatementData.data } as EStatementModalData;
    if (this.eStatementData.sendMail === true) {
      this.eStatementRadio = EStatement.Electronic;
    } else {
      this.eStatementRadio = EStatement.Paper;
    }
    this.eStatementLoading = false;
  };

  public setEStatementRadio = (radio: EStatement): void => {
    this.eStatementRadio = radio;
  };
  public setEStatementEmail = (email: string): void => {
    if (this.eStatementData) {
      this.eStatementData.email = email;
    }
  };

  public clearEStatement = (): void => {
    if (this.eStatementData) {
      this.eStatementData = undefined;
    }
    this.eStatementMessage = "";
  };

  public UpdateEStatement = async (
    userEmployee: UserEmployee
  ): Promise<void> => {
    this.eStatementLoading = true;
    if (this.eStatementData) {
      const dataUpdate: EStatementSignData = {
        AccountNumber: this.accountInformation.accountNumber,
        Email: this.eStatementData.email,
        SendMail: this.eStatementRadio === EStatement.Electronic,
        Username: userEmployee.employeeName,
      };
      const updateEStatementSign =
        await this._api.RicApiClient.postEStatementSign(dataUpdate);
      console.log(updateEStatementSign.data);
      this.getNotes();
      this.eStatementMessage = updateEStatementSign.data;
    }
    this.eStatementLoading = false;
  };
}
export default RicStore;
