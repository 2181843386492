const environments = {
  development: {
    UI_BaseUrl:'http://localhost:3001/',
    baseUrl: ["https://localhost:7021/", "http://localhost:57523/api/ric/v1/"],
    apiKey: "60F5B299-85B6-4EF9-9623-0C29A71DE0B3",
  },
  production: {
    UI_BaseUrl:'https://allaccesshub.hoa-it.com/',
    baseUrl: [
      "https://aamallaccesshubapi.azurewebsites.net/",
      "https://spapi.associatedasset.com/api/ric/v1/"
    ],
    apiKey: "60F5B299-85B6-4EF9-9623-0C29A71DE0B3",
  },
  test: {
    UI_BaseUrl:'https://yellow-tree-0fe639e1e.5.azurestaticapps.net/',
    baseUrl: [
      "https://devallaccesshub.azurewebsites.net/",
      "https://spapi.associatedasset.com/api/ric/v1/" //"https://testspapi.associatedasset.com/api/ric/v1/"
    ],
    apiKey: "60F5B299-85B6-4EF9-9623-0C29A71DE0B3",
  },
};

const getEnv = () => {
  const env = process.env.NODE_ENV || "development";
  const config = environments[env];

  if (!config) {
    console.error(`Environment configuration for '${env}' not found.`);
    return null;
  }

  console.log(env, "process.env.NODE_ENV");
  console.log(config, "Environment Configuration");
  return config;
};

export const env = getEnv();

