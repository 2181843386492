import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Radio,
  Tooltip
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import DynamicTable from "../../../Components/Table/Table";
import { Disclaimers } from "../../../redux/Actions";
import UserData from "../../../utils/UserData";
import TextArea from "antd/es/input/TextArea";
import Spinner from "../../../Components/Spinner/Spiner";
import SelectBox from "../../../Components/SelectBox/SelectBox";
import Headers from "../../../redux/Actions/Headers/Header";
import moment from "moment";
const Disclaimer = () => {
  const dispatch = useDispatch();
  const user = UserData();
  const response = useSelector((state) => state.Disclaimers);
  const allStatesAndCommunities = useSelector(
    (state) => state.Disclaimers?.allStatesAndCommunities?.data
  );
  const disclaimers = useSelector(
    (state) => state.Disclaimers?.disclaimers?.data
  );
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isCreateModalVisible, setCreateModalVisible] = useState(false);
  const [editingDisclaimer, setEditingDisclaimer] = useState(null);
  const [disclaimerFor, setDisclaimerFor] = useState([]);
  const [placeholder, setPlaceholder] = useState("Select");

  useEffect(() => {
    dispatch(Disclaimers.GetStatesAndCommunitiesRequest());
    dispatch(Disclaimers.GetAllDisclaimersRequest());
  }, [dispatch]);

  const handleEdit = (record) => {
    setEditingDisclaimer(record);
    formEdit.setFieldsValue(record);
    setEditModalVisible(true);
    console.log(record, "referenceId referenceId", stateOptions);
  };
  useEffect(() => {
    dispatch(
      Headers.setHeader({
        title: "Tenant Registration Disclaimers",
        actions: [
          {
            label: "Create Disclaimer",
            type: "button",
            placeholder: "Search",
            icon:<PlusCircleOutlined />,
            onClick : () => setCreateModalVisible(true),
            className: "createDisclaimer",
          },
        ],
      })
    );
    return () => dispatch(Headers.resetHeader());
  }, [dispatch]);

  const handleDelete = (referenceId) => {
    console.log(referenceId);
    let req = {
      referenceId: referenceId,
    };
    dispatch(Disclaimers.DeleteDisclaimerRequest(req));

    message.success("Disclaimer deleted successfully");
  };

  const handleSubmitEdit = (values) => {
    console.log(values);
    console.log(values, "dsfjlsjflsjdlfjsdjflsdjfsjdklfjsdlkjflsd");
    const req = {
      refType: editingDisclaimer?.refType,
      disclaimer: values?.disclaimer,
      referenceId: values?.referenceId,
      updatedBy: user?.getUserInformation()?.displayName,
      UpdationDate: new Date().toISOString(), // Get current UTC date in ISO 8601 format
    };

    dispatch(Disclaimers.AddOrUpdateDisclaimerRequest({ request: req }));
    dispatch(Disclaimers.GetAllDisclaimersRequest());

    message.success("Fee updated successfully");
    form.resetFields();
    setPlaceholder("Select");
    setEditModalVisible(false);
  };

  const handleSubmitCreate = (values) => {
    console.log(values);
    const req = {
      refType: values?.type,
      disclaimer: values?.disclaimer,
      referenceId: values?.refId,
      updatedBy: user?.getUserInformation()?.displayName,
      UpdationDate: new Date().toISOString(), // Get current UTC date in ISO 8601 format
    };

    dispatch(Disclaimers.AddOrUpdateDisclaimerRequest({ request: req }));
    dispatch(Disclaimers.GetAllDisclaimersRequest());

    message.success("Fee created successfully");
    form.resetFields();
    setPlaceholder("Select");
    setCreateModalVisible(false);
  };

  // Define a search function for the Property column
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters,confirm) => {
    clearFilters();
    setPlaceholder("Select");
    confirm();
  };
  const columns = [
    {
      width:150,
      title: "Property/State",
      dataIndex: "referenceId",
      key: "referenceId",
          sorter: (a, b) => {
        const communityA = allStatesAndCommunities?.communities.find(community => community.communityId === a.referenceId);
        const communityB = allStatesAndCommunities?.communities.find(community => community.communityId === b.referenceId);
        const stateA = allStatesAndCommunities?.states.find(state => state.stateId === a.referenceId);
        const stateB = allStatesAndCommunities?.states.find(state => state.stateId === b.referenceId);
        const nameA = communityA ? communityA.communityName : stateA ? stateA.stateName : '';
        const nameB = communityB ? communityB.communityName : stateB ? stateB.stateName : '';
        console.log(nameA, nameB, "sorter");
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Property"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters,confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      onFilter: (value, record) => {
        if (record.refType == "property") {
          const community = allStatesAndCommunities?.communities.find(
            (community) => community.communityId === record.referenceId
          );
          return community
            ? community.communityName
                .toLowerCase()
                .includes(value.toLowerCase())
            : false;
        } else {
          const state = allStatesAndCommunities?.states.find(
            (state) => state.stateId === record.referenceId
          );
          return state
            ? state.stateName.toLowerCase().includes(value.toLowerCase())
            : false;
        }
      },
      render: (text, record) => {
        if (record.refType == "property") {
          const community = allStatesAndCommunities?.communities.find(
            (community) => community.communityId === text
          );
          return community ? community.communityName : "N/A"; // Fallback if community is not found
        } else {
          const state = allStatesAndCommunities?.states.find(
            (state) => state.stateId === text
          );
          return state ? state.stateName : "N/A"; // Fallback if community is not found
        }
      },
    },
    {
      width:100,
      title: "Type",
      dataIndex: "refType",
      key: "refType",
      sorter: (a, b) => a.refType.localeCompare(b.refType),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Type"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters, confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      onFilter: (value, record) =>
        record.refType.toLowerCase().includes(value.toLowerCase()),
    },
    {
      width: 450,
      title: 'Disclaimer',
      dataIndex: 'disclaimer',
      key: 'disclaimer',
      sorter: (a, b) => a.disclaimer.localeCompare(b.disclaimer),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Disclaimer"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters, confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      onFilter: (value, record) =>
        record.disclaimer.toLowerCase().includes(value.toLowerCase()),
  
      render: (disclaimer) => {
        // Truncate the disclaimer to 100 characters
        const truncatedText = disclaimer.length > 100 ? `${disclaimer.substring(0, 122)}...` : disclaimer;
  
        // Render the Tooltip with the full disclaimer as title
        return (
          <Tooltip title={disclaimer}>
            <div>{truncatedText}</div>
          </Tooltip>
        );
      },
    },
    {
      width:150,
      title: "Updated Date",
      dataIndex: "updationDate",
      key: "updationDate",
      sorter: (a, b) => new Date(a.updationDate) - new Date(b.updationDate),
      render: (text) => {
        // Convert UTC time to local time using Moment.js
        return moment.utc(text).local().format("M/D/YYYY, hh:mm:ss A");
      },
    },
    {
      width:80,
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record.referenceId)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const activeDisclaimers = disclaimers
    ?.filter((fee) => fee.status === "Active")
    .sort((a, b) => new Date(b.updationDate) - new Date(a.updationDate)); // Sort by latest date (desc)
  console.log(activeDisclaimers, "activeDisclaimers");
  const activePropertyIds = activeDisclaimers?.map((fee) => fee.referenceId);

  const communityOptions = useMemo(() => {
    if (isEditModalVisible && !isCreateModalVisible) {
      return (
        allStatesAndCommunities?.communities?.map((community) => ({
          name: community.communityName, // Display name in dropdown
          value: community.communityId, // Value to be used in form submission
        })) || []
      );
    } else {
      return (
        allStatesAndCommunities?.communities
          ?.filter(
            (community) => !activePropertyIds?.includes(community.communityId)
          )
          .map((community) => ({
            name: community.communityName, // Display name in dropdown
            value: community.communityId, // Value to be used in form submission
          })) || []
      );
    }
  }, [
    isEditModalVisible,
    allStatesAndCommunities,
    disclaimers,
    isCreateModalVisible,
  ]);

  const stateOptions = useMemo(() => {
    if (isEditModalVisible && !isCreateModalVisible) {
      return (
        allStatesAndCommunities?.states?.map((state) => ({
          name: state.stateName, // Display name in dropdown
          value: state.stateId, // Value to be used in form submission
        })) || []
      );
    } else {
      return (
        allStatesAndCommunities?.states
          ?.filter((state) => !activePropertyIds?.includes(state.stateId))
          .map((state) => ({
            name: state.stateName, // Display name in dropdown
            value: state.stateId, // Value to be used in form submission
          })) || []
      );
    }
  }, [
    isEditModalVisible,
    allStatesAndCommunities,
    isCreateModalVisible,
    disclaimers,
  ]);

  const onSearch = (value) => {
    console.log(value);
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  function HandleOnChange(selected) {
    if (selected.target.value == "state") {
      setDisclaimerFor(stateOptions);
      setPlaceholder("Select State");
      form.resetFields(["refId"]);
    }
    if (selected.target.value == "property") {
      setDisclaimerFor(communityOptions);
      setPlaceholder("Select Property");
      form.resetFields(["refId"]);
    }
  }

  const disclaimerOptions = [
    {
      value: "state",
      label: "State",
    },
    {
      value: "property",
      label: "Property",
    },
  ];

  return (
    <>
      {response?.loading && <Spinner />}

      <div>

        <DynamicTable
        className='custom-table'
          rowKey="referenceId"
          needDefaultWidth={true}
          columns={columns}
          data={activeDisclaimers}
          pagination={{ pageSize: 10 }}
          onChange={() => {}}
        />

        {/* Edit Fee Modal */}
        <Modal
          title="Edit Disclaimer"
          visible={isEditModalVisible}
          maskClosable={false}
          onCancel={() => {
            setEditModalVisible(false);
            form.resetFields();
            setPlaceholder("Select");
          }}
          footer={null}
        >
          <Form form={formEdit} onFinish={handleSubmitEdit}>
            {editingDisclaimer?.refType == "state" ? (
              <SelectBox
                disabled={true}
                rules={[{ required: true }]}
                placeholder="Select Property"
                name="referenceId"
                label="Disclaimers For"
                onChange={() => {}}
                options={stateOptions}
              />
            ) : (
              <SelectBox
                disabled={true}
                rules={[{ required: true }]}
                placeholder="Select Property"
                onChange={() => {}}
                name="referenceId"
                label="Disclaimers For"
                options={communityOptions}
              />
            )}

            <Form.Item
              name="disclaimer"
              label="Disclaimer"
              rules={[{ required: true, message: "Required" }]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="updatedBy" label="Last Updated By">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
              <Button
                style={{ marginLeft: 3 }}
                type="primary"
                onClick={() => {
                  setEditModalVisible(false);
                  form.resetFields();
                  setPlaceholder("Select");
                }}
              >
                cancel
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Create Fee Modal */}
        <Modal
          title="Create Disclaimer"
          visible={isCreateModalVisible}
          onCancel={() => {
            setCreateModalVisible(false);
            form.resetFields();
            setPlaceholder("Select");
          }}
          footer={null}
        >
          <Form form={form} onFinish={handleSubmitCreate}>
            <Form.Item
              name="type"
              label="Choose the option for which you want to add the disclaimer."
              rules={[{ required: true, message: "Required" }]}
              style={{ marginBottom: "2rem" }}
            >
              <Radio.Group
                options={disclaimerOptions}
                onChange={HandleOnChange}
              />
            </Form.Item>
            {console.log(form.getFieldsValue(), "dljsdlfjsdlfsd")}
            {placeholder !== "Select" && (
              <SelectBox
                rules={[{ required: true }]}
                placeholder="Select an option"
                name="refId"
                options={disclaimerFor}
                size="large"
                className="custom-scrollbar"
                onChange={() => {}}
                showSearch={true}
                onSearch={onSearch}
                filterOption={filterOption}
              />
            )}

            <Form.Item
              name="disclaimer"
              label="Enter Disclaimer"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default Disclaimer;
