import Constant from '../../Constants/Constants';
  
  const initialState = {
    registrationFees:{},
    addedOrUpdatedRegistrationFee:{},
    deletedRegistrationFee:{},
    loading: false,
    error: null,
  };
 class RegistrationFees{
    static GetAllRegistrationFeesListReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.GET_ALL_REGISTRATION_FEES_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_ALL_REGISTRATION_FEES_SUCCESS:
            return {
              ...state,
              loading: false,
              registrationFees: action.payload,
              error: null,
            };
          case Constant.GET_ALL_REGISTRATION_FEES_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.ADD_OR_UPDATE_REGISTRATION_FEE_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.ADD_OR_UPDATE_REGISTRATION_FEE_SUCCESS:
            return {
              ...state,
              loading: false,
              addedOrUpdatedRegistrationFee: action.payload,
              error: null,
            };
          case Constant.ADD_OR_UPDATE_REGISTRATION_FEE_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.DELETE_REGISTRATION_FEE_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.DELETE_REGISTRATION_FEE_SUCCESS:
            return {
              ...state,
              loading: false,
              deletedRegistrationFee: action.payload,
              error: null,
            };
          case Constant.DELETE_REGISTRATION_FEE_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            default:
                return state;
            }
          };
  }
  export default RegistrationFees;