import {env} from './EnvirementConfig';
class PrefixPath{
    static APP_NAME = 'All-Access-Hub';
    static API_BASE_URL = env?.baseUrl[0];
    static UI_BASE_URL  = env?.UI_BaseUrl;
    static RIC_API_BASE_URL = env?.baseUrl[1];
    static API_KEY = env?.apiKey;
    static APP_PREFIX_PATH = '/AAM';
    static AUTH_PREFIX_PATH = '/auth';
static DEVELOPER_ID='0703a81c'
static HASH_KEY='a2bfc11b-616f-4692-b814-c0b988b0f577'
static USER_ID='11efabfd41d6322ab1d35a10'
static LOCATION_ID='11efabfd3a8ee0e8aa0edb8c'
static CC_PRODUCT_TRANSACTION_ID='11efabfd3b651c44b92d3b2b'
static ACH_PRODUCT_TRANSACTION_ID='11efabfd3f4a3d089d586066'
}
export default PrefixPath;