import Constant from "../../Constants/Constants";

const initialState = {
  users: [],
  CommunityDetails: [],
  Features: [],
  UpdateFeature: "",
  GetUsers: [],
  GetFeatures: [],
  CreateUser: {},
  SetFeatures: "",
  usersDetails: {},
  UpdateUser: {},
  UpdateUserDirectory: {},
  getBoardVueUsers : [],
  getCommunities:[],
  loading: false,
  error: null,
};
class Users {
  static GetAllUsersListReducer = (state = initialState, action) => {
    switch (action.type) {
      case Constant.GET_ALL_USERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_ALL_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          userDetails: action.payload,
          error: null,
        };
      case Constant.GET_ALL_USERS_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_COMMUNITY_BY_USERID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_COMMUNITY_BY_USERID_SUCCESS:
        return {
          ...state,
          loading: false,
          CommunityDetails: action.payload,
          error: null,
        };
      case Constant.FILTER_COMMUNITY_BY_USERID_SUCCESS:
        return {
          ...state,
          loading: false,
          CommunityDetails: action.payload,
          error: null,
        };
      case Constant.GET_COMMUNITY_BY_USERID_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_BOARD_FEATURES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_BOARD_FEATURES_SUCCESS:
        return {
          ...state,
          loading: false,
          BoardFeatures: action.payload,
          error: null,
        };
      case Constant.GET_BOARD_FEATURES_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_ALL_FEATURES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_ALL_FEATURES_SUCCESS:
        return {
          ...state,
          loading: false,
          Features: action.payload,
          error: null,
        };
      case Constant.GET_ALL_FEATURES_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.UPDATE_FEATURES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.UPDATE_FEATURES_SUCCESS:
        return {
          ...state,
          loading: false,
          UpdateFeature: action.payload,
          error: null,
        };
      case Constant.UPDATE_FEATURES_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_USERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          GetUsers: action.payload,
          error: null,
        };
      case Constant.GET_USERS_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_FEATURES_BY_COMMUNITYID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_FEATURES_BY_COMMUNITYID_SUCCESS:
        return {
          ...state,
          loading: false,
          GetFeatures: action.payload,
          error: null,
        };
      case Constant.GET_FEATURES_BY_COMMUNITYID_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.SET_FEATURES_BY_COMMUNITYID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.SET_FEATURES_BY_COMMUNITYID_SUCCESS:
        return {
          ...state,
          loading: false,
          SetFeatures: action.payload,
          error: null,
        };
      case Constant.SET_FEATURES_BY_COMMUNITYID_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.GET_USERS_BYID_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.GET_USERS_BYID_SUCCESS:
        return {
          ...state,
          loading: false,
          usersDetails: action.payload,
          error: null,
        };
      case Constant.GET_USERS_BYID_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.CREATE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.CREATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          CreateUser: action.payload,
          error: null,
        };
      case Constant.RESET_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          CreateUser: {},
          error: null,
        };
      case Constant.CREATE_USER_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.UPDATE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.UPDATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          UpdateUser: action.payload,
          error: null,
        };
      case Constant.UPDATE_USER_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.USER_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.USER_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          userStatus: action.payload,
          error: null,
        };
      case Constant.USER_STATUS_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case Constant.USER_REGENRATE_PASSWORD_REQUEST:
        return {
          ...state,
          genrateLoading: true,
          error: null,
        };
      case Constant.USER_REGENRATE_PASSWORD_SUCCESS:
        return {
          ...state,
          genrateLoading: false,
          password: action.payload,
          error: null,
        };
      case Constant.USER_REGENRATE_PASSWORD_FAILURE:
        return {
          ...state,
          error: action.payload,
          genrateLoading: false,
        };
        case Constant.USER_DIRECTORY_UPDATE_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case Constant.USER_DIRECTORY_UPDATE_SUCCESS:
          return {
            ...state,
            loading: false,
            UpdateUserDirectory: action.payload,
            error: null,
          };
        case Constant.USER_DIRECTORY_UPDATE_FAILURE:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
          case Constant.GET_BOARD_VUE_USER_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case Constant.GET_BOARD_VUE_USER_SUCCESS:
            return {
              ...state,
              loading: false,
              getBoardVueUsers: action.payload,
              error: null,
            };
          case Constant.GET_BOARD_VUE_USER_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.GET_COMMUNITIES_USER_BYID_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case Constant.GET_COMMUNITIES_USER_BYID_SUCCESS:
            return {
              ...state,
              loading: false,
              getCommunities: action.payload,
              error: null,
            };
          case Constant.GET_COMMUNITIES_USER_BYID_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.ADD_BOARD_VUE_USER_BYID_REQUEST:
              return {
                ...state,
                loading: true,
              };
              case Constant.FILTER_BOARD_VUE_BY_USERID_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getBoardVueUsers: action.payload,
                  error: null,
                };
              case Constant.ADD_BOARD_VUE_USER_BYID_SUCCESS: {
                console.log(action?.payload, "action?.payload 66666");
            
                let newData = Array.isArray(action?.payload) ? action.payload : [];
              console.log(newData,"newData")
                return {
                    ...state,
                    loading: false,
                    getBoardVueUsers: state.getBoardVueUsers?.length > 0
                        ? [...state.getBoardVueUsers, ...newData]
                        : [...newData],  // Default to newData if state is not an array
                    error: null,
                };
            }
            
            
            case Constant.ADD_BOARD_VUE_USER_BYID_FAILURE:
              return {
                ...state,
                error: action.payload,
                loading: false,
              };
              case Constant.DELETE_BOARD_VUE_USER_BYID_REQUEST:
                return {
                  ...state,
                  loading: true,
                };
              case Constant.DELETE_BOARD_VUE_USER_BYID_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  deleteRes: action.payload,
                  error: null,
                };
              case Constant.DELETE_BOARD_VUE_USER_BYID_FAILURE:
                return {
                  ...state,
                  error: action.payload,
                  loading: false,
                };
      default:
        return state;
    }
  };
  //   static GetCommunityByUserIdReducer = (state = initialState, action) => {
  //     switch (action.type) {
  //         case Constant.GET_COMMUNITY_BY_USERID_REQUEST:
  //             return {
  //               ...state,
  //               loading: true,
  //               error: null,
  //             };
  //       case Constant.GET_COMMUNITY_BY_USERID_SUCCESS:
  //         return {
  //           ...state,
  //           loading: false,
  //           CommunityDetails: action.payload,
  //           error: null,
  //         };
  //       case Constant.GET_COMMUNITY_BY_USERID_FAILURE:
  //         return {
  //           ...state,
  //           error: action.payload,
  //           loading: false,
  //         };
  //       default:
  //         return state;
  //     }
  //   };
}
export default Users;
