import moment from "moment";
class CommonFunctions {
  static disableFutureDates = (current) => {

    return current && current > moment().endOf("day");
  };

  static formatDate(inputDate) {
    if (inputDate) {
      return moment(inputDate).format("MM/DD/YYYY");
    }
    return "-";
  }
  static getDigits = (str, number) => {
    return str.slice(number);
  };
  static hasRole=(user, roleName) =>{
    return user?.roles?.some((role) => role?.roleName === roleName);
  }
static formatPhoneNumber = (input) => {
    if (!input) return "";
    const cleaned = ("" + input).replace(/\D/g, "").slice(0, 10);
    // Format the cleaned input
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join("-");
    }
    return input;
  };

  static getStatusMessage = (status_id) => {
    switch(status_id) {
        case 101:
            return 'Approved';
        case 102:
            return 'AuthOnly';
        case 111:
            return 'Refunded';
        case 121:
            return 'AvsOnly';
        case 131:
            return 'Pending Origination';
        case 132:
            return 'Originating';
        case 133:
            return 'Originated';
        case 134:
            return 'Settled';
        case 135:
            return 'Reserved';
        case 136:
            return 'Rejected';
        case 191:
            return 'Settled (deprecated - batches are now settled on the /v2/transactionbatches endpoint)';
        case 201:
            return 'Voided';
        case 301:
            return 'Declined';
        case 331:
            return 'Charged Back';
        default:
            return null;
    }
  }
  
  
  
  static getReasonCodeMessage = (code) => {
    const codeMessages = {
      0: 'N/A',
      //1000: isAchPayment?'ACH - Accepted':'CC - Approved',
      1001: 'AuthCompleted',
      1002: 'Forced',
      1003: 'AuthOnly Declined',
      1004: 'Validation Failure (System Run Trx)',
      1005: 'Processor Response Invalid',
      1200: 'Voided',
      1201: 'Partial Approval',
      1500: 'Generic Decline',
      1510: 'Call',
      1518: 'Transaction Not Permitted - Terminal',
      1520: 'Pickup Card',
      1530: 'Retry Trx',
      1531: 'Communication Error',
      1540: 'Setup Issue, contact Support',
      1541: 'Device is not signature capable',
      1588: 'Data could not be de-tokenized',
      1599: 'Other Reason',
      1601: 'Generic Decline',
      1602: 'Call',
      1603: 'No Reply',
      1604: 'Pickup Card - No Fraud',
      1605: 'Pickup Card - Fraud',
      1606: 'Pickup Card - Lost',
      1607: 'Pickup Card - Stolen',
      1608: 'Account Error',
      1609: 'Already Reversed',
      1610: 'Bad PIN',
      1611: 'Cashback Exceeded',
      1612: 'Cashback Not Available',
      1613: 'CID Error',
      1614: 'Date Error',
      1615: 'Do Not Honor',
      1616: 'NSF',
      1617: 'Exceeded Withdrawal Limit',
      1618: 'Invalid Service Code',
      1619: 'Exceeded activity limit',
      1620: 'Violation',
      1621: 'Encryption Error',
      1622: 'Card Expired',
      1623: 'Renter',
      1624: 'Security Violation',
      1625: 'Card Not Permitted',
      1626: 'Trans Not Permitted',
      1627: 'System Error',
      1628: 'Bad Merchant ID',
      1629: 'Duplicate Batch (Already Closed)',
      1630: 'Batch Rejected',
      1631: 'Account Closed',
      1650: 'Contact Support',
      1651: 'Max Sending - Throttle Limit Hit (ACH only)',
      1652: 'Max Attempts Exceeded',
      1653: 'Contact Support',
      1654: 'Voided - Online Reversal Failed',
      1655: 'Decline (AVS Auto Reversal)',
      1656: 'Decline (CVV Auto Reversal)',
      1657: 'Decline (Partial Auth Auto Reversal)',
      1658: 'Expired Authorization',
      1659: 'Declined - Partial Approval not Supported',
      1660: 'Bank Account Error, please delete and re-add Account Vault',
      1661: 'Declined AuthIncrement',
      1662: 'Auto Reversal - Processor can\'t settle',
      1663: 'Manager Needed (Needs override transaction)',
      1664: 'Account Vault Not Found: Sharing Group Unavailable',
      1665: 'Contact Not Found: Sharing Group Unavailable',
      1701: 'Chip Reject',
      1800: 'Incorrect CVV',
      1801: 'Duplicate Transaction',
      1802: 'MID/TID Not Registered',
      1803: 'Stop Recurring',
      1804: 'No Transactions in Batch',
      1805: 'Batch Does Not Exist'
    };
  
    // Return the message corresponding to the reason code
    return codeMessages[code] || null;
  };
  
  
  // Function to handle type_id and return message
  static getTypeMessage = (type_id) => {
    switch(type_id) {
        case 20:
            return 'Sale';
        case 21:
            return 'AVS Only';
        case 22:
            return 'Settle (deprecated - batches are now settled on the /v2/transactionbatches endpoint)';
        case 30:
            return 'Refund';
        case 40:
            return 'Credit';
        case 50:
            return 'Debit';
        default:
            return null;
    }
  }
}

export default CommonFunctions;