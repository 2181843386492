import Constant from "../../Constants/Constants";
class Disclaimers {
  static GetAllDisclaimersRequest = (credentials) => ({
    type: Constant.GET_ALL_DISCALIMERS_REQUEST,
    payload: credentials,
  });

  static GetAllDisclaimersSuccess = (userData) => ({
    type: Constant.GET_ALL_DISCALIMERS_SUCCESS,
    payload: userData,
  });
  
  static GetAllDisclaimersFailure = (error) => ({
    type: Constant.GET_ALL_DISCALIMERS_FAILURE,
    payload: error,
  });

  static DeleteDisclaimerRequest = (credentials) => ({
    type: Constant.DELETE_DISCALIMER_REQUEST,
    payload: credentials,
  });

  static DeleteDisclaimerSuccess = (userData) => ({
    type: Constant.DELETE_DISCALIMER_SUCCESS,
    payload: userData,
  });
  
  static DeleteDisclaimerFailure = (error) => ({
    type: Constant.DELETE_DISCALIMER_FAILURE,
    payload: error,
  });

  static AddOrUpdateDisclaimerRequest = (credentials) => ({
    type: Constant.ADD_OR_UPDATE_DISCALIMER_REQUEST,
    payload: credentials,
  });

  static AddOrUpdateDisclaimerSuccess = (userData) => ({
    type: Constant.ADD_OR_UPDATE_DISCALIMER_SUCCESS,
    payload: userData,
  });
  
  static AddOrUpdateDisclaimerFailure = (error) => ({
    type: Constant.ADD_OR_UPDATE_DISCALIMER_FAILURE,
    payload: error,
  });

  static GetStatesAndCommunitiesRequest = (credentials) => ({
    type: Constant.GET_STATES_AND_COMMUNITIES_REQUEST,
    payload: credentials,
  });

  static GetStatesAndCommunitiesSuccess = (userData) => ({
    type: Constant.GET_STATES_AND_COMMUNITIES_SUCCESS,
    payload: userData,
  });
  
  static GetStatesAndCommunitiesFailure = (error) => ({
    type: Constant.GET_STATES_AND_COMMUNITIES_FAILURE,
    payload: error,
  });
}
export default Disclaimers;