class Constant {
  static LOGIN_REQUEST = "LOGIN_REQUEST";
  static LOGIN_SUCCESS = "LOGIN_SUCCESS";
  static LOGIN_FAILURE = "LOGIN_FAILURE";

  static LOGOUT = "LOGOUT";
  static IS_SIDEBAR = "IS_SIDEBAR";
  static AUTH_TOKEN = "auth_token";
  static AUTH_USER = "userData";
  static GET_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
  static GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
  static GET_DETAILS_FAILURE = "GET_DETAILS_FAILURE";

  static GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
  static GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
  static GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

  static GET_COMMUNITY_BY_USERID_REQUEST = "GET_COMMUNITY_BY_USERID_REQUEST";
  static GET_COMMUNITY_BY_USERID_SUCCESS = "GET_COMMUNITY_BY_USERID_SUCCESS";
  static GET_COMMUNITY_BY_USERID_FAILURE = "GET_COMMUNITY_BY_USERID_FAILURE";
  static FILTER_COMMUNITY_BY_USERID_SUCCESS = "FILTER_COMMUNITY_BY_USERID_SUCCESS";

  static GET_ALL_FEATURES_REQUEST = "GET_ALL_FEATURES_REQUEST";
  static GET_ALL_FEATURES_SUCCESS = "GET_ALL_FEATURES_SUCCESS";
  static GET_ALL_FEATURES_FAILURE = "GET_ALL_FEATURES_FAILURE";

  static GET_BOARD_FEATURES_REQUEST = "GET_BOARD_FEATURES_REQUEST";
  static GET_BOARD_FEATURES_SUCCESS = "GET_BOARD_FEATURES_SUCCESS";
  static GET_BOARD_FEATURES_FAILURE = "GET_BOARD_FEATURES_FAILURE";

  static UPDATE_FEATURES_REQUEST = "UPDATE_FEATURES_REQUEST";
  static UPDATE_FEATURES_SUCCESS = "UPDATE_FEATURES_SUCCESS";
  static UPDATE_FEATURES_FAILURE = "UPDATE_FEATURES_FAILURE";

  static GET_USERS_REQUEST = "GET_USERS_REQUEST";
  static GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
  static GET_USERS_FAILURE = "GET_USERS_FAILURE";

  static GET_FEATURES_BY_COMMUNITYID_REQUEST = "GET_FEATURES_BY_COMMUNITYID_REQUEST";
  static GET_FEATURES_BY_COMMUNITYID_SUCCESS = "GET_FEATURES_BY_COMMUNITYID_SUCCESS";
  static GET_FEATURES_BY_COMMUNITYID_FAILURE = "GET_FEATURES_BY_COMMUNITYID_FAILURE";

  static SET_FEATURES_BY_COMMUNITYID_REQUEST = "SET_FEATURES_BY_COMMUNITYID_REQUEST";
  static SET_FEATURES_BY_COMMUNITYID_SUCCESS = "SET_FEATURES_BY_COMMUNITYID_SUCCESS";
  static SET_FEATURES_BY_COMMUNITYID_FAILURE = "SET_FEATURES_BY_COMMUNITYID_FAILURE";

  static GET_USERS_BYID_REQUEST = "GET_USERS_BYID_REQUEST";
  static GET_USERS_BYID_SUCCESS = "GET_USERS_BYID_SUCCESS";
  static GET_USERS_BYID_FAILURE = "GET_USERS_BYID_FAILURE";

  static SET_USER_ROLE = "SET_USER_ROLE";

  static GET_ALL_COMMUNITYS_REQUEST = "GET_ALL_COMMUNITYS_REQUEST";
  static GET_ALL_COMMUNITYS_SUCCESS = "GET_ALL_COMMUNITYS_SUCCESS";
  static GET_ALL_COMMUNITYS_FAILURE = "GET_ALL_COMMUNITYS_FAILURE";

  static GET_ALL_TENANT_SUBMISSIONS_REQUEST = "GET_ALL_TENANT_SUBMISSIONS_REQUEST";
  static GET_ALL_TENANT_SUBMISSIONS_SUCCESS = "GET_ALL_TENANT_SUBMISSIONS_SUCCESS";
  static GET_ALL_TENANT_SUBMISSIONS_FAILURE = "GET_ALL_TENANT_SUBMISSIONS_FAILURE";

  static GET_COMMUNITY_BYID_REQUEST = "GET_COMMUNITY_BYID_REQUEST";
  static GET_COMMUNITY_BYID_SUCCESS = "GET_COMMUNITY_BYID_SUCCESS";
  static GET_COMMUNITY_BYID_FAILURE = "GET_COMMUNITY_BYID_FAILURE";

  static GET_TENANT_BYID_REQUEST = "GET_TENANT_BYID_REQUEST";
  static GET_TENANT_BYID_SUCCESS = "GET_TENANT_BYID_SUCCESS";
  static GET_TENANT_BYID_FAILURE = "GET_TENANT_BYID_FAILURE";

  static UPDATED_COMMUNITY_BYID_REQUEST = "UPDATED_COMMUNITY_BYID_REQUEST";
  static UPDATED_COMMUNITY_BYID_SUCCESS = "UPDATED_COMMUNITY_BYID_SUCCESS";
  static UPDATED_COMMUNITY_BYID_FAILURE = "UPDATED_COMMUNITY_BYID_FAILURE";

  static UPDATED_TENANT_BYID_REQUEST = "UPDATED_TENANT_BYID_REQUEST";
  static UPDATED_TENANT_BYID_SUCCESS = "UPDATED_TENANT_BYID_SUCCESS";
  static UPDATED_TENANT_BYID_FAILURE = "UPDATED_TENANT_BYID_FAILURE";

  static UPDATED_TENANT_PAYMENT_REQUEST = "UPDATED_TENANT_PAYMENT_REQUEST";
  static UPDATED_TENANT_PAYMENT_SUCCESS = "UPDATED_TENANT_PAYMENT_SUCCESS";
  static UPDATED_TENANT_PAYMENT_FAILURE = "UPDATED_TENANT_PAYMENT_FAILURE";

  static STATUS_UPDATE_COMMUNITY_BYID_REQUEST = "STATUS_UPDATE_COMMUNITY_BYID_REQUEST";
  static STATUS_UPDATE_COMMUNITY_BYID_SUCCESS = "STATUS_UPDATE_COMMUNITY_BYID_SUCCESS";
  static STATUS_UPDATE_COMMUNITY_BYID_FAILURE = "STATUS_UPDATE_COMMUNITY_BYID_FAILURE";

  static PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST = "PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST";
  static PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS = "PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS";
  static PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE = "PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE";
  
  static CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
  static CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
  static CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
  static RESET_USER_SUCCESS = "RESET_USER_SUCCESS";

  static UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
  static UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
  static UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

  static USER_STATUS_REQUEST = "USER_STATUS_REQUEST";
  static USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";
  static USER_STATUS_FAILURE = "USER_STATUS_FAILURE";

  static USER_REGENRATE_PASSWORD_REQUEST = "USER_REGENRATE_PASSWORD_REQUEST";
  static USER_REGENRATE_PASSWORD_SUCCESS = "USER_REGENRATE_PASSWORD_SUCCESS";
  static USER_REGENRATE_PASSWORD_FAILURE = "USER_REGENRATE_PASSWORD_FAILURE";

  static GET_ALL_DISCALIMERS_REQUEST = "GET_ALL_DISCALIMERS_REQUEST";
  static GET_ALL_DISCALIMERS_SUCCESS = "GET_ALL_DISCALIMERS_SUCCESS";
  static GET_ALL_DISCALIMERS_FAILURE = "GET_ALL_DISCALIMERS_FAILURE";

  static DELETE_DISCALIMER_REQUEST = "DELETE_DISCALIMER_REQUEST";
  static DELETE_DISCALIMER_SUCCESS = "DELETE_DISCALIMER_SUCCESS";
  static DELETE_DISCALIMER_FAILURE = "DELETE_DISCALIMER_FAILURE";
  
  static ADD_OR_UPDATE_DISCALIMER_REQUEST = "ADD_OR_UPDATE_DISCALIMER_REQUEST";
  static ADD_OR_UPDATE_DISCALIMER_SUCCESS = "ADD_OR_UPDATE_DISCALIMER_SUCCESS";
  static ADD_OR_UPDATE_DISCALIMER_FAILURE = "ADD_OR_UPDATE_DISCALIMER_FAILURE";

  static GET_ALL_REGISTRATION_FEES_REQUEST = "GET_ALL_REGISTRATION_FEES_REQUEST";
  static GET_ALL_REGISTRATION_FEES_SUCCESS = "GET_ALL_REGISTRATION_FEES_SUCCESS";
  static GET_ALL_REGISTRATION_FEES_FAILURE = "GET_ALL_REGISTRATION_FEES_FAILURE";

  static DELETE_REGISTRATION_FEE_REQUEST = "DELETE_REGISTRATION_FEE_REQUEST";
  static DELETE_REGISTRATION_FEE_SUCCESS = "DELETE_REGISTRATION_FEE_SUCCESS";
  static DELETE_REGISTRATION_FEE_FAILURE = "DELETE_REGISTRATION_FEE_FAILURE";
  
  static ADD_OR_UPDATE_REGISTRATION_FEE_REQUEST = "ADD_OR_UPDATE_REGISTRATION_FEE_REQUEST";
  static ADD_OR_UPDATE_REGISTRATION_FEE_SUCCESS = "ADD_OR_UPDATE_REGISTRATION_FEE_SUCCESS";
  static ADD_OR_UPDATE_REGISTRATION_FEE_FAILURE = "ADD_OR_UPDATE_REGISTRATION_FEE_FAILURE";

  static GET_STATES_AND_COMMUNITIES_REQUEST = "GET_STATES_AND_COMMUNITIES_REQUEST";
  static GET_STATES_AND_COMMUNITIES_SUCCESS = "GET_STATES_AND_COMMUNITIES_SUCCESS";
  static GET_STATES_AND_COMMUNITIES_FAILURE = "GET_STATES_AND_COMMUNITIES_FAILURE";



  static SET_HEADER = "SET_HEADER";
  static RESET_HEADER = "RESET_HEADER";

  static USER_DIRECTORY_UPDATE_REQUEST = "USER_DIRECTORY_UPDATE_REQUEST";
  static USER_DIRECTORY_UPDATE_SUCCESS = "USER_DIRECTORY_UPDATE_SUCCESS";
  static USER_DIRECTORY_UPDATE_FAILURE = "USER_DIRECTORY_UPDATE_FAILURE";

  static GET_BOARD_VUE_USER_REQUEST = "GET_BOARD_VUE_USER_REQUEST";
  static GET_BOARD_VUE_USER_SUCCESS = "GET_BOARD_VUE_USER_SUCCESS";
  static GET_BOARD_VUE_USER_FAILURE = "GET_BOARD_VUE_USER_FAILURE";

  static GET_COMMUNITIES_USER_BYID_REQUEST = "GET_COMMUNITIES_USER_BYID_REQUEST";
  static GET_COMMUNITIES_USER_BYID_SUCCESS = "GET_COMMUNITIES_USER_BYID_SUCCESS";
  static GET_COMMUNITIES_USER_BYID_FAILURE = "GET_COMMUNITIES_USER_BYID_FAILURE";
  static FILTER_BOARD_VUE_BY_USERID_SUCCESS = "FILTER_BOARD_VUE_BY_USERID_SUCCESS";

  static ADD_BOARD_VUE_USER_BYID_REQUEST = "ADD_BOARD_VUE_USER_BYID_REQUEST";
  static ADD_BOARD_VUE_USER_BYID_SUCCESS = "ADD_BOARD_VUE_USER_BYID_SUCCESS";
  static ADD_BOARD_VUE_USER_BYID_FAILURE = "ADD_BOARD_VUE_USER_BYID_FAILURE";

  static DELETE_BOARD_VUE_USER_BYID_REQUEST = "DELETE_BOARD_VUE_USER_BYID_REQUEST";
  static DELETE_BOARD_VUE_USER_BYID_SUCCESS = "DELETE_BOARD_VUE_USER_BYID_SUCCESS";
  static DELETE_BOARD_VUE_USER_BYID_FAILURE = "DELETE_BOARD_VUE_USER_BYID_FAILURE";

}

export default Constant;
