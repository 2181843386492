import Constant from "../../Constants/Constants";
class Communitys {
  static GetAllCommunitysRequest = (credentials) => ({
    type: Constant.GET_ALL_COMMUNITYS_REQUEST,
    payload: credentials,
  });

  static GetAllCommunitysSuccess = (userData) => ({
    type: Constant.GET_ALL_COMMUNITYS_SUCCESS,
    payload: userData,
  });
  
  static GetAllCommunitysFailure = (error) => ({
    type: Constant.GET_ALL_COMMUNITYS_FAILURE,
    payload: error,
  });

  static GetCommunityByIdRequest = (credentials) => ({
    type: Constant.GET_COMMUNITY_BYID_REQUEST,
    payload: credentials,
  });

  static GetCommunityByIdSuccess = (userData) => ({
    type: Constant.GET_COMMUNITY_BYID_SUCCESS,
    payload: userData,
  });
  
  static GetCommunityByIdFailure = (error) => ({
    type: Constant.GET_COMMUNITY_BYID_FAILURE,
    payload: error,
  });

  static UpdatedCommunityByIdRequest = (credentials) => ({
    type: Constant.GET_COMMUNITY_BYID_REQUEST,
    payload: credentials,
  });

  static UpdatedCommunityByIdSuccess = (userData) => ({
    type: Constant.GET_COMMUNITY_BYID_SUCCESS,
    payload: userData,
  });
  
  static UpdatedCommunityByIdFailure = (error) => ({
    type: Constant.GET_COMMUNITY_BYID_FAILURE,
    payload: error,
  });

  static EditCommunityByIdRequest = (credentials) => ({
    type: Constant.UPDATED_COMMUNITY_BYID_REQUEST,
    payload: credentials,
  });

  static EditCommunityByIdSuccess = (userData) => ({
    type: Constant.UPDATED_COMMUNITY_BYID_SUCCESS,
    payload: userData,
  });
  
  static EditCommunityByIdFailure = (error) => ({
    type: Constant.UPDATED_COMMUNITY_BYID_FAILURE,
    payload: error,
  });
  static StatusUpdateCommunityByIdRequest = (credentials) => ({
    type: Constant.STATUS_UPDATE_COMMUNITY_BYID_REQUEST,
    payload: credentials,
  });

  static StatusUpdateCommunityByIdSuccess = (userData) => ({
    type: Constant.STATUS_UPDATE_COMMUNITY_BYID_SUCCESS,
    payload: userData,
  });
  
  static StatusUpdateCommunityByIdFailure = (error) => ({
    type: Constant.STATUS_UPDATE_COMMUNITY_BYID_FAILURE,
    payload: error,
  });
  

  static PermissionUpdateCommunityByIdRequest = (credentials) => ({
    type: Constant.PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST,
    payload: credentials,
  });

  static PermissionUpdateCommunityByIdSuccess = (userData) => ({
    type: Constant.PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS,
    payload: userData,
  });
  
  static PermissionUpdateCommunityByIdFailure = (error) => ({
    type: Constant.PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE,
    payload: error,
  });


}
export default Communitys;