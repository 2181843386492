import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const calculateColumnWidth = (text, padding = 16) => {
  return text?.length * 8 + padding;
};

const generateColumnsWithWidth = (columns, data) => {
  return columns.map((column) => {
    const longestValue = data?.reduce((max, row) => {
      const value = row[column.dataIndex];
      return value && value?.length > max?.length ? value : max;
    }, column.title);

    const width = calculateColumnWidth(longestValue);
    return { ...column, width, className: 'custom-column-class' }; 
  });
};

const DynamicTable = ({ rowKey, onRow, expandable, columns, data, pagination, onChange, needDefaultWidth}) => {
  const columnsWithWidth = generateColumnsWithWidth(columns, data).map(column => ({
    ...column,
    width: column.width || 200, // Adjust width as needed (e.g., 200px)
  }));

  return (
    <Table 
      columns={needDefaultWidth ? columns : columnsWithWidth}   
      dataSource={data}
      pagination={pagination}
      rowKey={rowKey}
      expandable={expandable}
      rowClassName={'table-header'}
      onRow={onRow}
      scroll={{ x: 400, y: 'calc(100vh - 300px)' }}
      onChange={onChange}
    />
  );
};

DynamicTable.propTypes = {
  rowKey: PropTypes.string.isRequired,
  onRow: PropTypes.func.isRequired,
  expandable: PropTypes.object,
  scroll: PropTypes.object,  // Optional: Not used but can be added
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  needDefaultWidth: PropTypes.bool,
};

DynamicTable.defaultProps = {
  expandable: {},
  scroll: {},
  pagination: true,
  needDefaultWidth:false
};

export default DynamicTable;
