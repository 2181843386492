import Constant from '../../Constants/Constants';
  
  const initialState = {
    getAllTenants:[],
    updateTenantById:{},
    getTenantById:{},
    updatePayment:{},
    loading: false,
    error: null,
  };
 class Tenants{
    static GetAllTenantSubmissionsListReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.GET_ALL_TENANT_SUBMISSIONS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_ALL_TENANT_SUBMISSIONS_SUCCESS:
            return {
              ...state,
              loading: false,
              getAllTenants: action.payload,
              error: null,
            };
          case Constant.GET_ALL_TENANT_SUBMISSIONS_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.GET_TENANT_BYID_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_TENANT_BYID_SUCCESS:
            return {
              ...state,
              loading: false,
              getTenantById: action.payload,
              error: null,
            };
          case Constant.GET_TENANT_BYID_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.UPDATED_TENANT_BYID_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.UPDATED_TENANT_BYID_SUCCESS:
            return {
              ...state,
              loading: false,
              updateTenantById: action.payload,
              error: null,
            };
          case Constant.UPDATED_TENANT_BYID_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.UPDATED_TENANT_PAYMENT_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.UPDATED_TENANT_PAYMENT_SUCCESS:
            return {
              ...state,
              loading: false,
              updatePayment: action.payload,
              error: null,
            };
          case Constant.UPDATED_TENANT_PAYMENT_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            default:
                return state;
            }
          };
  }
  export default Tenants;