class List{
    static Permissions = [
        {
          name: "All Acceess Web User",
          value: 0,
        },
        {
          name: "Finance",
          value: 2,
        },
        {
          name: "Board Member",
          value: 1,
        },
        {
          name: "Architectural",
          value: 3,
        },
      ];

      static directoryColumns = [
        { title: "First Name ", dataIndex: "firstName" },
        { title: "Last Name ", dataIndex: "lastName" },
        {
          title: "Email",
          dataIndex: "mudEmail",
        },
        { title: "Login Email ", dataIndex: "loginEmail" },
        { title: "Address ", dataIndex: "mudAddress" },
        { title: "Home Phone ", dataIndex: "homePhone" },
        { title: "Cell Phone ", dataIndex: "cellPhone" },
        { title: "City ", dataIndex: "city" },
        { title: "State ", dataIndex: "state" },
        { title: "Zip     ", dataIndex: "zip" },
      ];

      
  static vehicleColumns = [
    { title: "Make", dataIndex: "make", key: "make" },
    { title: "Model", dataIndex: "model", key: "model" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Color", dataIndex: "color", key: "color" },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Spot", dataIndex: "spot", key: "spot" },
  ];

  static petColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Breed", dataIndex: "breed", key: "breed" },
    {
      title: "Rabies Due Date",
      dataIndex: "rabiesDueDate",
      key: "rabiesDueDate",
    },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "Age", dataIndex: "age", key: "age" },
  ];

  // Lease Columns
  static leaseColumns = [
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
    { title: "End Date", dataIndex: "endDate", key: "endDate" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];
}

export default List;