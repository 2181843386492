/* eslint-disable */
import { Col, Form, Row } from "antd";
import React from "react";
import Component from "../../../../Components";
import Validation from "utils/Validation";
import Lists from "../../../../utils/CommonList/CommonList";
import Spinner from "../../../../Components/Spinner/Spiner";
const AddBoardUser = ({form,loading,onFinsish,onChange,getCommunities})=>{
    const {Button,SelectBox} = Component
 
     const validationRules = Validation.getValidation([
        "permissionId",
        "communityId",
     
      ]);

    return(
        <> 
        {loading && <Spinner />}
        <Form  layout="vertical"
          className="ant-form"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }} form={form} onFinish={onFinsish}>
           <Row justify={'space-between'} >
           <Col sm={11}>
            <SelectBox
              options={getCommunities ?? []}
              className="user-form-input"
              name={`communityId`}
              placeholder="Community"
              size="md"
              hasFeedback={true}
              validateTrigger={"onChange"}
              onChange={onChange}
              rules={validationRules.communityId}
            />
          
           </Col>
            <Col sm={11}>
            <SelectBox
              options={Lists?.Permissions ?? []}
              className="user-form-input"
              name={`permissionId`}
              placeholder="Permission"
              size="md"
              hasFeedback={true}
              validateTrigger={"onChange"}
              onChange={onChange}
              rules={validationRules.permissionId}
            />
          
            </Col>
           
          <Col sm={24} >
         <div style={{textAlign:'right'}}>
         <Button  htmlType="submit"
                  title={"Add"}
                //  loading={uDirectoryUpdate?.loading}
                  className="create-button"
                   />
         </div>
          </Col>
           </Row>
          
        </Form>
        </>
       
    )
};
export default AddBoardUser;


