/* eslint-disable */
import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import {Disclaimers} from '../../Actions';
import JwtAuthService from '../../../services/JwtAuthService';


  function* GetAllDiscalimersList() {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Tenant/getAllDisclaimers`);
        yield put(Disclaimers.GetAllDisclaimersSuccess(response));
    } catch (error) {
        yield put(Disclaimers.GetAllDisclaimersFailure(error.message));
    }
    
}

function* DeleteDisclaimer(data) {
    try {
        const response = yield call(JwtAuthService.postApi,"",`api/Tenant/DeleteDisclaimer?referenceId=${data?.payload?.referenceId}`);
console.log(response,"    dispatch(Disclaimers.GetAllDisclaimersRequest());")
if(response.status){
    yield put(Disclaimers.GetAllDisclaimersRequest());
    yield put(Disclaimers.DeleteDisclaimerSuccess(response));
}    

    } catch (error) {
        yield put(Disclaimers.DeleteDisclaimerFailure(error.message));
    }
    
}

function* AddOrUpdateDisclaimer(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Tenant/addOrUpdateDisclaimer`);

        yield put(Disclaimers.AddOrUpdateDisclaimerSuccess(response));
        data?.payload?.handleCancel(response);
       
    } catch (error) {
        yield put(Disclaimers.AddOrUpdateDisclaimerFailure(error.message));
    }
    
}

function* GetAllStatesAndCommunities(data) {
    try {
        const response = yield call(JwtAuthService.postApi,"",`api/Tenant/getAllStatesAndCommunities`);

        yield put(Disclaimers.GetStatesAndCommunitiesSuccess(response));
        data?.payload?.handleCancel(response);
       
    } catch (error) {
        yield put(Disclaimers.GetStatesAndCommunitiesFailure(error.message));
    }
    
}

export function* Discalimer() {
  yield takeLatest(Constant.GET_ALL_DISCALIMERS_REQUEST, GetAllDiscalimersList);
  yield takeLatest(Constant.DELETE_DISCALIMER_REQUEST, DeleteDisclaimer);
  yield takeLatest(Constant.ADD_OR_UPDATE_DISCALIMER_REQUEST, AddOrUpdateDisclaimer);
  yield takeLatest(Constant.GET_STATES_AND_COMMUNITIES_REQUEST, GetAllStatesAndCommunities);
}
