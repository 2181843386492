import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from 'Views/Auth-Views/Login';
import PrivateRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { UtilsComponent } from 'Components';
import UnAuthorized from '../Views/App-Views/CommonPages/UnAuthorize';
import { Users, CreateUser, ViewUsers, CommunityPermission } from 'Views/App-Views';
import PrefixPath from 'config/AppConfig';
import AppComponent from '../Views/App-Views/RIC/Ric';
import AccountInfo from '../Views/App-Views/RIC/webparts/ric/components/RIC/Accounts/AccountInfo';
import Submissions from '../Views/App-Views/TenantSubmissions/Submissions';
import SubmissionDetails from '../Views/App-Views/TenantSubmissions/SubmissionDetails';
import Disclaimer from '../Views/App-Views/Disclaimers/Disclaimers';
import RegistrationFee from '../Views/App-Views/RegistrationFees/RegistrationFees';
import { PAGE_CODES } from '../Constant/Roles';
const { MainLayout } = UtilsComponent;

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute 
      element={<Navigate to={`${PrefixPath.APP_PREFIX_PATH}/dashboard`} />} 
      requiredPageCode={PAGE_CODES.USER_LIST} 
    />,
  },
  {
    path: `${PrefixPath.APP_PREFIX_PATH}/`,
    element: <MainLayout/>,
    children: [
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user`,
        element: <PrivateRoute element={<Users/>} requiredPageCode={PAGE_CODES.USER_LIST} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/ric`,
        element: <PrivateRoute element={<AppComponent/>} requiredPageCode={PAGE_CODES.RIC} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user/userDetails/:id`,
        element: <PrivateRoute element={<ViewUsers/>} requiredPageCode={PAGE_CODES.USER_DETAILS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user/CreateUser`,
        element: <PrivateRoute element={<CreateUser/>} requiredPageCode={PAGE_CODES.CREATE_USER} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/dashboard`,
        element: <PrivateRoute element={<>dashboard</>} requiredPageCode={PAGE_CODES.DASHBOARD} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/community/permissions`,
        element: <PrivateRoute element={<CommunityPermission/>} requiredPageCode={PAGE_CODES.COMMUNITY_PERMISSIONS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/Ric/Account/:id`,
        element: <PrivateRoute element={<AccountInfo/>} requiredPageCode={PAGE_CODES.ACCOUNT_INFO} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/community/permissions/:id`,
        element: <PrivateRoute element={<CommunityPermission/>} requiredPageCode={PAGE_CODES.COMMUNITY_PERMISSIONS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/TenantRegistrations`,
        element: <PrivateRoute element={<Submissions/>} requiredPageCode={PAGE_CODES.TENANT_REGISTRATIONS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/TenantRegistrations/View/`,
        element: <PrivateRoute element={<SubmissionDetails/>} requiredPageCode={PAGE_CODES.SUBMISSION_DETAILS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/Disclaimers`,
        element: <PrivateRoute element={<Disclaimer/>} requiredPageCode={PAGE_CODES.DISCLAIMERS} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/RegistrationFees`,
        element: <PrivateRoute element={<RegistrationFee/>} requiredPageCode={PAGE_CODES.REGISTRATION_FEES} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/unauthorized`,
        element: <PrivateRoute element={<UnAuthorized/>} />,
      },
    ],
  },
  {
    path: `/login`,
    element: '',
    children: [
      {
        path: `/login`,
        element: <PublicRoute element={<Login />} />,
      },
    ],
  },
]);

export default router;