import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const ModalComponent = (props) => {
    const { title, centered, open, Ok, isShow, Cancel, width, children, loading, pageName } = props;

    return (
        <Modal
            title={title}
            centered={centered}
            open={open}
            confirmLoading={loading}
            onOk={() => Ok(false)}
            onCancel={() => Cancel()}
            width={width}
            mask={true}
            maskClosable={false} 
            className="common-button custom-modal-height"
            footer={isShow ? true : false}
          //  bodyStyle={bodyStyle}
        >
            {children(pageName)}
        </Modal>
    );
};

ModalComponent.propTypes = {
    title:PropTypes.string,
    bodyStyle: PropTypes.object,
    centered: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    Ok: PropTypes.func.isRequired,
    isShow: PropTypes.bool,
    Cancel: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    pageName: PropTypes.string,
};

ModalComponent.defaultProps = {
    title: '',
    bodyStyle: {},
    centered: false,
    isShow: true,
    width: 520,
    loading: false,
    pageName: '',
};

export default ModalComponent;
