import React from "react";
import PropTypes from 'prop-types';
import { Button,Form } from "antd";
const ButtonComponent = (props) => {
  const { title, type,disabled, shape, icon,size,htmlType,wrapperCol,className,onClick ,loading} = props;
  return (
    <>
     <Form.Item
      wrapperCol={wrapperCol}
    >
      <Button
        shape={shape}
        icon={icon}
        className={className}
        type={type}
        size={size}
        htmlType={htmlType}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
      >
        {title}
      </Button>
      </Form.Item>
    </>
  );
};
ButtonComponent.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  shape: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  wrapperCol: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null, undefined, ""])]),
};

// Define default props (optional)
ButtonComponent.defaultProps = {
  disabled: false,
  htmlType: 'button',
  onClick: () => {},
};
export default ButtonComponent;
