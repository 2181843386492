import Constant from "../../Constants/Constants";
class RegistrationFees {
  static GetAllRegistrationFeesRequest = (credentials) => ({
    type: Constant.GET_ALL_REGISTRATION_FEES_REQUEST,
    payload: credentials,
  });

  static GetAllRegistrationFeesSuccess = (userData) => ({
    type: Constant.GET_ALL_REGISTRATION_FEES_SUCCESS,
    payload: userData,
  });
  
  static GetAllRegistrationFeesFailure = (error) => ({
    type: Constant.GET_ALL_REGISTRATION_FEES_FAILURE,
    payload: error,
  });

  static DeleteRegistrationFeeRequest = (credentials) => ({
    type: Constant.DELETE_REGISTRATION_FEE_REQUEST,
    payload: credentials,
  });

  static DeleteRegistrationFeeSuccess = (userData) => ({
    type: Constant.DELETE_REGISTRATION_FEE_SUCCESS,
    payload: userData,
  });
  
  static DeleteRegistrationFeeFailure = (error) => ({
    type: Constant.DELETE_REGISTRATION_FEE_FAILURE,
    payload: error,
  });

  static AddOrUpdateRegistrationFeeRequest = (credentials) => ({
    type: Constant.ADD_OR_UPDATE_REGISTRATION_FEE_REQUEST,
    payload: credentials,
  });

  static AddOrUpdateRegistrationFeeSuccess = (userData) => ({
    type: Constant.ADD_OR_UPDATE_REGISTRATION_FEE_SUCCESS,
    payload: userData,
  });
  
  static AddOrUpdateRegistrationFeeFailure = (error) => ({
    type: Constant.ADD_OR_UPDATE_REGISTRATION_FEE_FAILURE,
    payload: error,
  });
}
export default RegistrationFees;