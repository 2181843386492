/* eslint-disable */
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';
const SECRET_KEY = 'fDrtOnVl3t8OwV+0JjNDsQ=='; 

const cookies = new Cookies();
// const secretKey = process.env.REACT_APP_SECRET_KEY;
// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};

// const encryptData = (data) => {
//   return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
// };

// const decryptData = (encryptedData) => {
//   const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

// const setCookie = (key, value, minutes) => {
//   // var dt = new Date();
//   // var expireOn = new Date();
//   // expireOn.setMinutes(dt.getMinutes() + minutes);
//   // //const encryptedData = encryptData(value);
//   // cookies.set(key, value, { path: '/', expires: expireOn });
//   const expireOn = new Date();
//   expireOn.setHours(expireOn.getHours() + minutes);
//   cookies.set(key, value, { path: '/', expires: expireOn });
// };
const setCookie = (key, value, minutes) => {
  const expireOn = new Date();
  expireOn.setHours(expireOn.getHours() + minutes);
  cookies.set(key, value, { path: '/', expires: expireOn });
};

const removeCookie = (name) => {
  //cookies.set(name, '', { path: '/', expires: new Date(Date.now()) });
  const allCookies = cookies.getAll();
    
  // Iterate over all cookies and remove each one
  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, { path: '/' }); // Make sure to specify the correct path
  });
};

const getCookie = (key) => {
  const encryptedData = localStorage.getItem('userData');
  if(encryptedData){
const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

return decryptedData;
  }
};

const getAllCookies = () => {
  return cookies.getAll();
};

export { setCookie, getCookie, removeCookie, getAllCookies };
