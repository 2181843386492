import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
const SECRET_KEY = 'fDrtOnVl3t8OwV+0JjNDsQ=='; 
//import { getCookie } from './AllCookies';
//import Constant from '../redux/Constants/Constants';


const UserData = () => {
  const AuthUsers = useSelector((state) => state?.auth);
  const decryptData = () => {
    const encryptedData = localStorage.getItem('userData');
    
    if (!encryptedData) {
      console.error('No data found in localStorage');
      return null;
    }
    
    // Decrypt the data using the secret key
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    
    // Convert the decrypted data back into a readable JSON object
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
    return decryptedData;
  };
  const user = decryptData();
 
  const getUserName = () => {
    const data = user || AuthUsers || {};
    return `${data?.loginUserName}`;
  };

  const getUserLoginToken = () => {
    const {jwtToken} = user || AuthUsers || {};
    return jwtToken;
  };

  const getLoginName = () => {
    const firstName = user?.data?.firstName ;
    return firstName;
  };

  const getRoles = () => {
    return user?.role;
  };
const getAccessPages =()=> {
  return user?.accessPage || [];
}
  const getUserInformation = () => {
    return user ;
  };
 
  return {
    getUserName,
    getRoles,
    getUserInformation,
    getLoginName,
    getUserLoginToken,
    getAccessPages
  };
};

export default UserData;
