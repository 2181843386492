import Constant from "../../Constants/Constants";
class Tenants {
  static GetAllTenantSubmissionsRequest = (credentials) => ({
    type: Constant.GET_ALL_TENANT_SUBMISSIONS_REQUEST,
    payload: credentials,
  });

  static GetAllTenantSubmissionsSuccess = (userData) => ({
    type: Constant.GET_ALL_TENANT_SUBMISSIONS_SUCCESS,
    payload: userData,
  });
  
  static GetAllTenantSubmissionsFailure = (error) => ({
    type: Constant.GET_ALL_TENANT_SUBMISSIONS_FAILURE,
    payload: error,
  });

  static GetTenantSubmissionsByIdRequest = (credentials) => ({
    type: Constant.GET_TENANT_BYID_REQUEST,
    payload: credentials,
  });

  static GetTenantSubmissionsByIdSuccess = (userData) => ({
    type: Constant.GET_TENANT_BYID_SUCCESS,
    payload: userData,
  });
  
  static GetTenantSubmissionsByIdFailure = (error) => ({
    type: Constant.GET_TENANT_BYID_FAILURE,
    payload: error,
  });

  static UpdateTenantRequestStatusRequest = (credentials) => ({
    type: Constant.UPDATED_TENANT_BYID_REQUEST,
    payload: credentials,
  });

  static UpdateTenantRequestStatusSuccess = (userData) => ({
    type: Constant.UPDATED_TENANT_BYID_SUCCESS,
    payload: userData,
  });
  
  static UpdateTenantRequestStatusFailure = (error) => ({
    type: Constant.UPDATED_TENANT_BYID_FAILURE,
    payload: error,
  });
  static UpdateTenantPaymentRequest = (credentials) => ({
    type: Constant.UPDATED_TENANT_PAYMENT_REQUEST,
    payload: credentials,
  });

  static UpdateTenantPaymentSuccess = (userData) => ({
    type: Constant.UPDATED_TENANT_PAYMENT_SUCCESS,
    payload: userData,
  });
  
  static UpdateTenantPaymentFailure = (error) => ({
    type: Constant.UPDATED_TENANT_PAYMENT_FAILURE,
    payload: error,
  });
}
export default Tenants;