class ValidationMessage {
    static CHECKEmailType = "Please input your valid email!";
    static USERNAME = "Please input your userName!";
    static PASSWORD = "Please input your password!";
    static FIRST_NAME = "Please input your First Name!";
    static LAST_NAME = "Please input your Last Name!";
    static RESIDENT_CONTACT_KEY = "Please input your resident conatact Key!";
    static PHONE_NUMBER = "Please input your Phone Number!";
    static MAXNUMBER = "Phone number must be exactly 10 digits.";
    static PERMISSIONS = "Please select Permissions!";
    static EMAIL = "Please input your Email!";
    static ACCOUNT_NUMBER = "Please input your Email!";
    static INVALID_EMAIL = "Please input a valid Email!";
    static GENDER = "Please select Gender!";
    static RESIDENT_KEY = "Please select resident Key";
    static COMMUNITY = "Please select community";
    static UNITS = "Please select units";
    static STREET_AND_UNIT_NUMBER = "Please input your Street address!";
    static COMMUNITY_NAME = "Please input your Community Name!";
    static ZIP = "Please input your zip!";
    static UNIT_NAME = "Please Input Valid UnitName";
    static ACCOUNT_KEY = "Please Input Valid accountKey";
    static CC_FEE = "Please Input Valid ccFee";
    static SUBMISSION_FEE = "Please Input Valid submissionFee";
    static CC_SETTING = "Please Input Valid cCSetting";
    static CC_INTEGRATION_KEY = "Please Input Valid cCIntegrationKey";
    static CONTACT_NO = "Please Input Valid contactNo";
    static CITY = "Please Input Valid city";
    static STATE = "Please Input Valid state";
    static ZIP_CODE = "Please Input Valid zipCode";
    static COUNTY = "Please Input Valid county";
    static ROLE = "Please Input Valid role";
    static MUDEMAIL = "Please input your mud email!";
    static CELL_PHONE = "Please input your cell phone!";
  }
  
  export default ValidationMessage;