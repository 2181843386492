/* eslint-disable */
import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import {Users} from '../../Actions';
import JwtAuthService from '../../../services/JwtAuthService';
import { notification } from 'antd';


  function* GetAllUserList(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getAllUsers?pageNumber=${data?.payload?.request?.pageNumber}&pageSize=${data?.payload?.request?.pageSize}&filter=${data?.payload?.request?.filter ?? ''}`);
        yield put(Users.GetAllUsersSuccess(response));
    } catch (error) {
        yield put(Users.GetAllUsersFailure(error.message));
    }
    
}

function* GetCommunityByUserId(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/GetCommunityUsersByUserId?userId=${data?.payload?.request?.userId}`);
       let newarray = [];
       if(response?.data?.communityUsers?.length > 0){
        for(let items of  response?.data?.communityUsers){
            newarray.push({name:items?.communityName,value:items?.communityId})
           }
           response.data.communtiyList = newarray;
       }
        yield put(Users.GetCommunityByUserIdSuccess(response));
    } catch (error) {
        yield put(Users.GetCommunityByUserIdFailure(error.message));
    }
    
}

function* GetFeatures(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/GetFeatureCommunity?filter=${data?.payload?.request?.filter ?? ''}`);
        yield put(Users.GetAllFeatureSuccess(response));
    } catch (error) {
        yield put(Users.GetAllFeatureFailure(error.message));
    } 
    
}
function* GetBoardFeatures(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/GetBoardFeaturList?userId=${data?.payload?.request?.userId ?? ''}`);
        yield put(Users.GetBoardFeatureSuccess(response));
    } catch (error) {
        yield put(Users.GetBoardFeatureFailure(error.message));
    } 
    
}

function* UpdateFeatures(action) {
    try {
        const response = yield call(JwtAuthService.postApi,action?.payload.request,`api/Users/updateFeture`);
        yield put(Users.UpdateFeatureSuccess(response));
    } catch (error) {
        yield put(Users.UpdateFeatureFailure(error.message));
    } 
    
}

function* GetUsersList(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getUsers`);
        yield put(Users.GetUsersSuccess(response));
    } catch (error) {
        yield put(Users.GetUsersFailure(error.message));
    }
    
}

function* GetFeaturesByCommunityId(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Users/getFeatures`);
        yield put(Users.GetFeaturesByCommunityIdSuccess(response));
    } catch (error) {
        yield put(Users.GetFeaturesByCommunityIdFailure(error.message));
    }
    
}

function* SetFeaturesByCommunityId(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Users/setCommunityFeatures`);
        yield put(Users.SetFeaturesByCommunityIdSuccess(response));
        if(response?.status === true){
             notification["success"]({
            message: response?.message,
            description: "",
          });
        }
    } catch (error) {
        yield put(Users.SetFeaturesByCommunityIdFailure(error.message));
    }
    
}

function* GetUserById(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getUser?users=${data?.payload?.userId}`);
        yield put(Users.GetUserByIdSuccess(response));
    } catch (error) {
        yield put(Users.GetUserByIdFailure(error.message));
    }
    
}

function* CreateUser(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Users/CreateUser`);
        yield put(Users.CreateUserSuccess(response));
        if(response?.status){
        // notification["success"]({
        //     message: response?.message,
        //     description: "",
        //   });
        //data?.payload?.route('/AAM/user')
       }
       else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.CreateUserFailure(error.message));
    }
    
}

function* UpdateUser(data) {
    try {
        const response = yield call(JwtAuthService.updateApi,data?.payload?.request,`api/Users/UpdateUser`);
        yield put(Users.UpdateUserSuccess(response));
        if(response?.status){
        notification["success"]({
            message: response?.message,
            description: "",
          });
         
          const req = {
            userId: data?.payload?.request?.id,
          };
          yield put(Users.GetBoardFeatureRequest({ request: req }));
       }
       else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.UpdateUserFailure(error.message));
    }
    
}

function* userStatus(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/userStatus?userId=${data?.payload?.request?.userId}&status=${data?.payload?.request?.status}`);
        yield put(Users.UserStatusSuccess(response));
    } catch (error) {
        yield put(Users.UserStatusFailure(error.message));
    }
    
}

function* Password(data) {
    try {
        const response = yield call(JwtAuthService.postApi,'',`api/Users/regenerateUserPassword?userId=${data?.payload?.request?.userId}`);
      if(response?.status){
        response.userId = data?.payload?.request?.userId;
        yield put(Users.UserRegenratePasswordSuccess(response));
      }
      
    } catch (error) {
        yield put(Users.UserRegenratePasswordFailure(error.message));
    }
    
}

function* UpdateUserDirectory(data) {
    try {
        const response = yield call(JwtAuthService.updateApi,data?.payload?.request,`api/Users/updateUserDirectory`);
        yield put(Users.UserDirectoryUpdateSuccess(response));
       
        if(response?.status){
        notification["success"]({
            message: response?.message,
            description: "",
          });
          data?.payload?.handle()
       }
       else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.UserDirectoryUpdateFailure(error.message));
    }
    
}

function* GetBoardVueUsers(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getBoardVueCommunityUserById?userId=${data?.payload?.request?.userId}`);
       if(response?.status){
        yield put(Users.GetBoardVueUserSuccess(response?.data));
       }else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.GetBoardVueUserFailure(error.message));
    }
    
}

function* GetCommunitiesUserById(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getCommunitiesUserById?userId=${data?.payload?.request?.userId}`);
       if(response?.status){
        yield put(Users.GetCommunitiesUserByIdSuccess(response));
       }else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.GetCommunitiesUserByIdFailure(error.message));
    }
    
}

function* AddBoardVueUserById(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Users/addBoardVueUser`);
      
        if(response?.status){
        yield put(Users.AddBoardVueUserByIdSuccess(response?.data));
        notification["success"]({
            message: "Add Successfully",
            description: "",
          });
        
   
       }else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.AddBoardVueUserByIdFailure(error.message));
    }
    
}

function* DeleteBoardVueUserById(data) {
    try {
        const response = yield call(JwtAuthService.postApi,'',`api/Users/deleteBoardVueUser?userId=${data?.payload?.userId}`);
      
        if(response?.status){
            const filterData = data?.payload?.getBoardVueUsers?.filter(
                (allData) => allData?.id !== data?.payload?.id
              );
        yield put(Users.DeleteBoardVueUserByIdSuccess(response));
        yield put(Users.filterBoardVueUserByUserIdSuccess(filterData));
        notification["success"]({
            message: "Delete Successfully",
            description: "",
          });
        
   
       }else{
        notification["error"]({
            message: response?.message ?? "error",
            description: "",
          });
       }
    } catch (error) {
        yield put(Users.DeleteBoardVueUserByIdFailure(error.message));
    }
    
}
export function* User() {
  yield takeLatest(Constant.GET_ALL_USERS_REQUEST, GetAllUserList);
  yield takeLatest(Constant.GET_COMMUNITY_BY_USERID_REQUEST, GetCommunityByUserId);
  yield takeLatest(Constant.GET_ALL_FEATURES_REQUEST, GetFeatures);
  yield takeLatest(Constant.GET_BOARD_FEATURES_REQUEST,GetBoardFeatures);
  yield takeLatest(Constant.UPDATE_FEATURES_REQUEST,UpdateFeatures);
  yield takeLatest(Constant.GET_USERS_REQUEST,GetUsersList);
  yield takeLatest(Constant.GET_FEATURES_BY_COMMUNITYID_REQUEST,GetFeaturesByCommunityId);
  yield takeLatest(Constant.SET_FEATURES_BY_COMMUNITYID_REQUEST,SetFeaturesByCommunityId);
  yield takeLatest(Constant.GET_USERS_BYID_REQUEST,GetUserById);
  yield takeLatest(Constant.CREATE_USER_REQUEST,CreateUser);
  yield takeLatest(Constant.UPDATE_USER_REQUEST,UpdateUser);
  yield takeLatest(Constant.USER_STATUS_REQUEST,userStatus);
  yield takeLatest(Constant.USER_REGENRATE_PASSWORD_REQUEST,Password);
  yield takeLatest(Constant.USER_DIRECTORY_UPDATE_REQUEST,UpdateUserDirectory);
  yield takeLatest(Constant.GET_BOARD_VUE_USER_REQUEST,GetBoardVueUsers);
  yield takeLatest(Constant.GET_COMMUNITIES_USER_BYID_REQUEST,GetCommunitiesUserById);
  yield takeLatest(Constant.ADD_BOARD_VUE_USER_BYID_REQUEST,AddBoardVueUserById);
  yield takeLatest(Constant.DELETE_BOARD_VUE_USER_BYID_REQUEST,DeleteBoardVueUserById);
  
}
