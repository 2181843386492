import Constant from '../../Constants/Constants';
  
  const initialState = {
    disclaimers:[],
    addedOrUpdatedDisclaimer:{},
    deletedDisclaimer:{},
    allStatesAndCommunities:{},
    loading: false,
    error: null,
  };
 class Disclaimers{
    static GetAllDisclaimersListReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.GET_ALL_DISCALIMERS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_ALL_DISCALIMERS_SUCCESS:
            return {
              ...state,
              loading: false,
              disclaimers: action.payload,
              error: null,
            };
          case Constant.GET_ALL_DISCALIMERS_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.ADD_OR_UPDATE_DISCALIMER_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.ADD_OR_UPDATE_DISCALIMER_SUCCESS:
            return {
              ...state,
              loading: false,
              addedOrUpdatedDisclaimer: action.payload,
              error: null,
            };
          case Constant.ADD_OR_UPDATE_DISCALIMER_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.DELETE_DISCALIMER_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.DELETE_DISCALIMER_SUCCESS:
            return {
              ...state,
              loading: false,
              deletedDisclaimer: action.payload,
              error: null,
            };
          case Constant.DELETE_DISCALIMER_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.GET_STATES_AND_COMMUNITIES_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_STATES_AND_COMMUNITIES_SUCCESS:
            return {
              ...state,
              loading: false,
              allStatesAndCommunities: action.payload,
              error: null,
            };
          case Constant.GET_STATES_AND_COMMUNITIES_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            default:
                return state;
            }
          };
  }
  export default Disclaimers;