/* eslint-disable */
import { observer } from "mobx-react-lite";
import { Button, Col, Flex, Popconfirm, Row, Space, Spin } from "antd";
import { ReactElement } from "react";
import AccountInfoCard from "./AccountInfoCard";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import AddContactInfoModal from "../AddContactInfoModal";
import { TypeAddModal } from "../../../../stores/RicStore";
import EStatementModal from "../eStatementModal";
import { useNavigate } from "react-router-dom";

const AccountInfoBlock = observer((): ReactElement => {
  const { ricStore, applicationStore } = useStore();
   const navigate = useNavigate();
  return (
    <>

      {ricStore.accountInformation?.accountNumber && (
        <>
       <Flex   align="start"
          wrap="wrap" gap={16} style={{ margin: "15px 0" }}>
  <Button onClick={() => navigate("/AAM/ric", { state: { PrpertyApiCall: true } })}>
    Back
  </Button>

  {(!ricStore.accountInformation?.futuresAccNumbers ||
    ricStore.accountInformation.futuresAccNumbers.length === 0) &&
    ricStore.accountInformation.isMainAcc && (
     <div style={{ width: "180px" }}>
 <AddContactInfoModal text="Add Future Account" type={TypeAddModal.AddFeatureContact} />
     </div>
  )}

  {(!ricStore.accountInformation?.rentersAccNumbers ||
    ricStore.accountInformation.rentersAccNumbers.length === 0) &&
    ricStore.accountInformation.isMainAcc && (
      <div  style={{ width: "180px" }}>
          <AddContactInfoModal  text="Add Renter Account" type={TypeAddModal.AddRentedContact} />
      </div>
    
  )}
  <div  style={{ width: "180px" }}>
            
              <Spin spinning={ricStore.isLoadLoaderZEGO}>
                <Button
                  block
                   //type="primary"
                  onClick={() => {
                    ricStore.createPdf();
                  }}
                  className="primary-btn"
                >
                  ZEGO Pay Now Info
                </Button>
              </Spin>
         
  </div>
  <div  style={{ width: "180px" }}>
  {ricStore.accountInformation?.rentersAccNumbers &&
            (ricStore.accountInformation.rentersAccNumbers.length > 0 ||
              ricStore.accountInformation.futuresAccNumbers.length > 0) ? (
              <section className="right-menu-button-link">
                {ricStore.accountInformation.isMainAcc !== true ? (
                  <Button onClick={ricStore.toMainAcc}>
                    Return to Main Account
                  </Button>
                ) : null}
              </section>
            ) : null}
  </div>
  <div  style={{ width: "180px" }}>   {ricStore.accountInformation?.rentersAccNumbers &&
              ricStore.accountInformation.rentersAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button onClick={ricStore.accesAccountRenter}>
                  Access Linked Renter Account
                </Button>
              ) : null}</div>
  <div  style={{ width: "180px" }}>{ricStore.accountInformation?.futuresAccNumbers &&
              ricStore.accountInformation.futuresAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button onClick={ricStore.accesAccountFuture}>
                  Access Linked Future Account
                </Button>
              ) : null}</div>
              <div style={{ width: "180px" }}>
              {ricStore.accountInformation?.activeRenters?.length == 0 &&
              ricStore.accountInformation.subLedgerType ==
                "Owner" ? null : ricStore.accountInformation.subLedgerType ==
                "Renter" ? (
                <Popconfirm
                  title="DEACTIVATE ACCOUNT"
                  description={
                    <div style={{ width: 250 }}>
                      Are you sure you want to deactivate this account? This is
                      not reversible and <br />
                      the account will lose access to all AAM systems.
                    </div>
                  }
                  okText="Confirm"
                  cancelText="Cancel"
                  onConfirm={() => {
                    ricStore
                      .deactivateAccountRenter()
                      .then((res) => {
                        ricStore.getFilterList(
                          applicationStore.SelectedProperties.id
                        );
                      })
                      .catch(console.log);
                  }}
                >
                  <Button
                    block
                    disabled={
                      !ricStore.isEditable ||
                      !ricStore.accountInformation?.isETrakType ||
                      !(ricStore.filterList?.eTrakAccounts?.length > 0)
                    }
                  >
                    Deactivate Renter Acct
                  </Button>
                </Popconfirm>
              ) : null}
              </div>
</Flex>

        <Flex
          align="start"
          wrap="wrap"
          style={{ display: "flex", flexDirection: "row", margin: "15px 0" }}
        >
          
          <div >
            <AccountInfoCard
              key={"accountInfo"}
              accountInfo={ricStore.accountInformation}
            />
          </div>
         
        </Flex>
        </>
      )}
    </>
  );
});

export default AccountInfoBlock;
