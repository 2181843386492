import { useNavigate } from 'react-router-dom';
import UserData from '../utils/UserData';
import { useIsAuthenticated } from '@azure/msal-react';
import PrefixPath from '../config/AppConfig';

function PrivateRoute({ element, requiredPageCode }) {
  const navigate = useNavigate();
  const { getUserLoginToken, getAccessPages } = UserData();
  const isAuthenticated = getUserLoginToken();
  const isToken = useIsAuthenticated();
  const userPermissions = getAccessPages();
  if (!isAuthenticated || !isToken) {
    return navigate('/login');
  }

  if (requiredPageCode && !userPermissions?.includes(requiredPageCode)) {
    return navigate(`${PrefixPath.APP_PREFIX_PATH}/unauthorized`,);
  }

  return element;
}

export default PrivateRoute;