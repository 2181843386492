/* eslint-disable */
import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import {RegistrationFees} from '../../Actions';
import JwtAuthService from '../../../services/JwtAuthService';


  function* GetAllRegistrationFeesList() {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Tenant/getAllRegistrationFees`);
        yield put(RegistrationFees.GetAllRegistrationFeesSuccess(response));
    } catch (error) {
        yield put(RegistrationFees.GetAllRegistrationFeesFailure(error.message));
    }
    
}

function* DeleteRegistrationFee(data) {
    try {
        const response = yield call(JwtAuthService.postApi,"",`api/Tenant/deleteRegistrationFee?propertyId=${data?.payload?.propertyId}`);

        yield put(RegistrationFees.DeleteRegistrationFeeSuccess(response));
    } catch (error) {
        yield put(RegistrationFees.DeleteRegistrationFeeFailure(error.message));
    }
    
}

function* AddOrUpdateRegistrationFee(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload?.request,`api/Tenant/addOrUpdateRegistrationFee`);

        yield put(RegistrationFees.AddOrUpdateRegistrationFeeSuccess(response));
        data?.payload?.handleCancel(response);
       
    } catch (error) {
        yield put(RegistrationFees.AddOrUpdateRegistrationFeeFailure(error.message));
    }
}

export function* RegistrationFee() {
  yield takeLatest(Constant.GET_ALL_REGISTRATION_FEES_REQUEST, GetAllRegistrationFeesList);
  yield takeLatest(Constant.DELETE_REGISTRATION_FEE_REQUEST, DeleteRegistrationFee);
  yield takeLatest(Constant.ADD_OR_UPDATE_REGISTRATION_FEE_REQUEST, AddOrUpdateRegistrationFee);
}
