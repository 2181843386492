import React, { useState, useEffect } from "react";
import { useStore } from "../../../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import { KnowledgeBase } from "../../../../api/base/ConfigApi";
import { Table, Tag, Typography, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// eslint-disable-next-line no-unused-vars
import type { ColumnsType, TableProps } from 'antd/es/table';

const { Text } = Typography;
const { Panel } = Collapse;

const PropertyFaqs: React.FC<{ companyKey: string }> = observer(({ companyKey }) => {
    const [propertyFaq, setPropertyFaq] = useState<KnowledgeBase[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { ricStore } = useStore();
    
    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                setLoading(true);
                const companykey = ricStore.accountInformation?.companyKey?.toString();
                if (!companykey) {
                    setLoading(false);
                    return;
                }
                
                const response = await ricStore.getPropertyFaq(companykey);
                
                if (Array.isArray(response)) {
                    setPropertyFaq(response as KnowledgeBase[]);
                }
                
                console.log(response, 'FAQ data fetched');
            } catch (error) {
                console.error("Error fetching FAQs:", error);
                setPropertyFaq([]);
            } finally {
                setLoading(false);
            }
        };
        
        fetchFaqs();
    }, [ricStore, companyKey]);
    
    const columns: ColumnsType<KnowledgeBase> = [
        {
            title: 'Subject',
            dataIndex: 'kb_Subject',
            key: 'kb_Subject',
            width: '15%',
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend','ascend'],
            sorter: (a, b) => a.kb_Subject.localeCompare(b.kb_Subject),
        },
        {
            title: 'Question/Answer',
            key: 'faq',
            width: '50%',
            render: (_, record) => (
                <Collapse 
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    bordered={false}
                    className="faq-collapse"
                >
                    <Panel 
                        header={<Text strong>{record.kb_Question}</Text>}
                        key="1"
                        className="faq-panel"
                    >
                        <div className="faq-answer">
                            {record.kb_Answer || <Text type="secondary">No answer provided</Text>}
                        </div>
                    </Panel>
                </Collapse>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'kb_Category',
            key: 'kb_Category',
            width: '15%',
            sortDirections: ['ascend', 'descend','ascend'],
            sorter: (a, b) => a.kb_Category.localeCompare(b.kb_Category),
            render: category => (
                <Tag color="blue">{category}</Tag>
            ),
        },
        {
            title: 'Web Access',
            dataIndex: 'kb_WebAccess',
            key: 'kb_WebAccess',
            width: '10%',
            render: webAccess => (
                <>
                    {webAccess ? 'Public' : ''}
                </>
            ),
        },
        {
            title: 'Limited to Companies',
            dataIndex: 'kb_LimitToSelectedCompanies',
            key: 'kb_LimitToSelectedCompanies',
            width: '10%',
            render: limited => (
                <>
                    {limited ? 1 : 0}
                </>
            ),
        },
    ];
    
    return (
        <div className="faq-container">
           
            <div className="ric-table">
                <Table
                    columns={columns}
                    dataSource={propertyFaq || []}
                    loading={loading}
                    pagination={false}
                    rowClassName={'table-header'}
                    size="small"
                    scroll={{
                      x: 'max-content',
                    }}
                />
            </div>
        
        </div>
    );
});

export default PropertyFaqs;