export const ROLES = {
  ADMIN: "admin",
  BOARD_ADMIN: "Board Admin",
  USER: "CAM",
};

export const ROLE_HIERARCHY = {
  [ROLES.ADMIN]: ["admin", "Board Admin", "User"],
  [ROLES.BOARD_ADMIN]: ["Board Admin"],
  [ROLES.USER]: ["CAM"],
};

export const PAGE_CODES = {
  DASHBOARD: "D1",
  USER_LIST: "U1",
  RIC: "R1",
  // USER_DETAILS: 'U1',
  // CREATE_USER: 'U1',
  TENANT_REGISTRATIONS: "T11",
  DISCLAIMERS: "T12",
  REGISTRATION_FEES: "T13",
  COMMUNITY_PERMISSIONS: "T1",
  // ACCOUNT_INFO: 'R1',
  // TENANT_REGISTRATIONS: 'T11',
  // SUBMISSION_DETAILS: 'T12',
};
