/* eslint-disable */
import { Switch, Form, Flex } from 'antd';
import React from 'react';

const SwitchInput = (props) => {
  const { label, name, className,value,onChange } = props;
const handleChange = (checked)=>{
let value = {
    target:{value:checked}
   
}
 onChange(name, value)
}
  return (
    <Form.Item name={name}  valuePropName="checked" className={className}>
      <Flex gap={16} align="center" wrap="wrap">
      {label && <p>{label}: </p>}
        <Switch id={name} checked={value}  onChange={handleChange}/>
       
      </Flex>
    </Form.Item>
  );
};

export default SwitchInput;
