import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
// import { useLocation } from 'react-router-dom';
// import DynamicBreadcrumb from './BreadCrumb';
// import pathToLabelMap from './pathToLabelMap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TextInput from '../TextInput/TextInput';
import SelectBox from '../SelectBox/SelectBox';
import SelectProperty from '../../Views/App-Views/RIC/webparts/ric/components/RIC/components/Search/SelectPropertys';
const { Title } = Typography;

const MainLayout = ({ children }) => {
  // const location = useLocation();
  const [form] = Form.useForm();
  const { title, actions } = useSelector((state) => state.Headers);
  return (
    <div className='main-layout' >
   <Row justify="space-between" align="middle" gutter={[0, 16]}>
  <Col xs={24} sm={24} md={12} lg={14}>
    <div style={{ width: '100%', marginLeft:10 }}>
      <Title 
        level={2} 
        className="text" 
        style={{ 
          wordWrap: 'break-word',
          wordBreak: 'normal',
          hyphens: 'auto',
          marginBottom: 8,
          display: 'block',
          fontSize: '22px'
        }}
      >
        {title}
      </Title>
    </div>
  </Col> 
  <Col xs={24}  sm={24}  md={12} lg={10}>
    <div style={{ 
      width: '100%', 
      display: 'flex', 
      justifyContent: 'flex-end', 
      flexWrap: 'wrap', 
      gap: '8px',
      textAlign: 'right'
    }}>
      {actions?.map((action, index) => {
        if (action.type === "button") {
          return (
            <Button
              key={index}
              type={action.buttonType || "default"}
              onClick={action.onClick}
              style={{ marginBottom: 8 }}
              className={action.className}
            >
              {action.label}
            </Button>
          );
        }
        console.log(action,"bactionactionactionactionactionactionaction")
        if (action?.type === "input") {
          return (
            <div key={index} style={{ width: '100%',textAlign:'right' }}>
              <Form
                layout="inline"
                className="ant-form"
                style={{ width: '100%',justifyContent:'end' }}
                form={action?.form}
              >
                
                  <TextInput
                    name={action.name}
                    value={action?.value}
                    placeholder={action?.placeholder}
                    className="search"
                    style={{ width: '100%' }}
                    icon={action?.icon}
                    onChange={action?.onClick}
                  />
               
              </Form>
            </div>
          );
        }
        
        if (action.type === "select") {
          return (
            <div key={index} style={{ width: '100%' }} className='ric-select'>
              <Form
                layout="inline"
                className="ant-form"
                style={{ width: '100%' }}
                form={form}
              >
                <Form.Item style={{ margin: 0, width: '100%' }}>
                  <SelectBox
                    name={action?.name}
                    options={action?.options}
                    value={action?.value}
                    placeholder={action?.placeholder}
                    className="search"
                    style={{ width: '100%' }}
                    onChange={action?.onClick}
                  />
                </Form.Item>
              </Form>
            </div>
          );
        }
        
        if (action.type === "property") {
          return (
            <div key={index} style={{ width: '100%' }}>
              <SelectProperty 
                onChange={action?.onClick} 
                style={{ width: '100%' }} 
              />
            </div>
          );
        }
        
        if (action.type === "div") {
          return (
            <div key={index} style={{ width: '100%' }}>
              {action.content}
            </div>
          );
        }
        
        return null;
      })}
      {children}
    </div>
  </Col>
</Row>
    </div>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
};
export default MainLayout;
