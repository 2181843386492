import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form, Input, message, Row, Col, Space, Select, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import DynamicTable from '../../../Components/Table/Table';
import { Disclaimers, RegistrationFees } from "../../../redux/Actions";
import UserData from '../../../utils/UserData';
import Spinner from '../../../Components/Spinner/Spiner';
import Headers from '../../../redux/Actions/Headers/Header';
import moment from 'moment';

const RegistrationFee = () => {
  const dispatch = useDispatch();
  const user = UserData();
  
  // Get data from Redux state
  const allStatesAndCommunities = useSelector((state) => state.Disclaimers?.allStatesAndCommunities?.data?.communities) || [];  // Add fallback empty array
  const registrationFees = useSelector((state) => state.RegistrationFees?.registrationFees?.data) || []; // Fallback to empty array if undefined
  const response = useSelector((state) => state.RegistrationFees);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isCreateModalVisible, setCreateModalVisible] = useState(false);
  const [editingFee, setEditingFee] = useState(null);
  const [form] = Form.useForm();  // Ant Design form instance for reset

  useEffect(() => {
    dispatch(Disclaimers.GetStatesAndCommunitiesRequest());
    dispatch(RegistrationFees.GetAllRegistrationFeesRequest());
  }, [dispatch]);
    useEffect(() => {
      dispatch(
        Headers.setHeader({
          title: "Tenant Registration Fees",
          actions: [
          ],
        })
      );
      return () => dispatch(Headers.resetHeader());
    }, [dispatch]);

  const handleEdit = (record) => {
    // Ensure we don't try to access undefined or null community data
    const community = allStatesAndCommunities.find(community => community.communityId === record.propertyId);
    console.log(record,"recordrecordrecordrecordrecord")
    if (community) {
      setEditingFee(record);
      form.setFieldsValue(record)
      setEditModalVisible(true);
    }
  };

  const handleDelete = (propertyId) => {
    let req = {
      propertyId: propertyId,
    };
    dispatch(RegistrationFees.DeleteRegistrationFeeRequest(req));
    dispatch(RegistrationFees.GetAllRegistrationFeesRequest());

    message.success('Fee deleted successfully');
  };

  const handleSubmitEdit = (values) => {
    const req = {
      propertyId: values?.propertyId,
      additionalFee: parseFloat(values?.additionalFee).toFixed(2),
      updatedBy: user?.getUserInformation()?.displayName,
      UpdationDate: new Date().toISOString(), // Get current UTC date in ISO 8601 format
    };

    dispatch(RegistrationFees.AddOrUpdateRegistrationFeeRequest({request:req}));
    dispatch(RegistrationFees.GetAllRegistrationFeesRequest());

    message.success('Fee updated successfully');
    setEditModalVisible(false);

    // Clear the form after submission
    form.resetFields();
  };

  const handleSubmitCreate = (values) => {
    const req = {
      propertyId: values?.propertyId,
      additionalFee: parseFloat(values?.additionalFee).toFixed(2),
      updatedBy: user?.getUserInformation()?.displayName,
      UpdationDate: new Date().toISOString(), // Get current UTC date in ISO 8601 format
    };

    dispatch(RegistrationFees.AddOrUpdateRegistrationFeeRequest({request:req}));
    dispatch(RegistrationFees.GetAllRegistrationFeesRequest());

    message.success('Fee created successfully');
    setCreateModalVisible(false);

    // Clear the form after submission
    form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const columns = [
    { 
      title: 'Property', 
      dataIndex: 'propertyId', 
      key: 'propertyId',
      sorter: (a, b) => {
        const communityA = allStatesAndCommunities.find(community => community.communityId === a.propertyId);
        const communityB = allStatesAndCommunities.find(community => community.communityId === b.propertyId);
        const nameA = communityA ? communityA.communityName : '';
        const nameB = communityB ? communityB.communityName : '';
        return nameA.localeCompare(nameB);
      },
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Property"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters, confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const community = allStatesAndCommunities.find(community => community.communityId === record.propertyId);
        return community ? community.communityName.toLowerCase().includes(value.toLowerCase()) : false;
      },
      render: (text) => {
        const community = allStatesAndCommunities.find(community => community.communityId === text);
        return community ? community.communityName : "N/A"; // Fallback if community is not found
      }
    },
    { 
      width:100,
      title: 'Fee', 
      dataIndex: 'additionalFee', 
      key: 'additionalFee',
      sorter: (a, b) => parseFloat(a.additionalFee) - parseFloat(b.additionalFee),
      render: (text) => `$${parseFloat(text).toFixed(2)}`,
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Fee"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters, confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        return record.additionalFee && record.additionalFee.toString().toLowerCase().includes(value.toLowerCase());
      }
    },
    { 
      title: 'Updated By', 
      dataIndex: 'updatedBy', 
      key: 'updatedBy',
      sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
      filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Updated By"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters, confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.updatedBy.toLowerCase().includes(value.toLowerCase())
    },
    { 
      title: 'Updated Date', 
      dataIndex: 'updationDate', 
      key: 'updationDate',
      sorter: (a, b) => new Date(a.updationDate) - new Date(b.updationDate),
         render: (text) => {
              // Convert UTC time to local time using Moment.js
              return moment.utc(text).local().format('M/D/YYYY, hh:mm:ss A');
            },
            filterIcon: (filtered) => (
                  <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Search Date"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters,confirm)}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const recordDate = new Date(record.updationDate).toLocaleString();
        return recordDate.toLowerCase().includes(value.toLowerCase());
      }
    },
    {
      width:100,
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record.propertyId)} />
          </Tooltip>
        </Space>
      ),
    },
  ];
  

  const activeRegistrationFees = registrationFees
  ?.filter(fee => fee.status === 'Active')
  .sort((a, b) => new Date(b.updationDate) - new Date(a.updationDate)); // Sort by latest date (desc)

  const activePropertyIds = activeRegistrationFees?.map(fee => fee.propertyId);

  const communityOptions = allStatesAndCommunities?.filter(community => !activePropertyIds?.includes(community.communityId))
    .map(community => ({
      label: community.communityName,   // Display name in dropdown
      value: community.communityId,     // Value to be used in form submission
    })) || [];  // Fallback empty array

  const onSearch = (value) => {
    console.log(value);
  };
  
  const filterOption = (input, option) =>
     (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  
  return (
     <>
         {(response?.loading) && <Spinner />}
    <div>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setCreateModalVisible(true)}>
            Create Fee
          </Button>
        </Col>
      </Row>

      <DynamicTable
        rowKey="referenceId"
        needDefaultWidth={true}
        columns={columns}
        data={activeRegistrationFees}
        pagination={{ pageSize: 10 }}
        onChange={() => {}}
      />

      {/* Edit Fee Modal */}
      <Modal
        title="Edit Fee"
        //initialValues={editingFee}
        maskClosable={false}
        visible={isEditModalVisible}
        onCancel={() => {setEditModalVisible(false); form.resetFields();}}
        footer={null}
      >
        <Form
          form={form}  // Attach the form instance her
          onFinish={handleSubmitEdit}
        >
          <Form.Item name="propertyId" label="Property">
            <Select placeholder="Select Property" disabled={true}>
              {allStatesAndCommunities?.map((community) => (
                <Select.Option key={community.communityId} value={community.communityId}>
                  {community.communityName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="additionalFee" label="Enter Fee in $" rules={[{ required: true }]}>
            <Input value={editingFee?.additionalFee} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
            <Button type="primary" style={{marginLeft:3}} onClick={()=>{setEditModalVisible(false); form.resetFields();}}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Create Fee Modal */}
      <Modal
        title="Create Fee"
        visible={isCreateModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmitCreate}>
          <Form.Item name="propertyId" label="Property" rules={[{ required: true }]}>
            <Select placeholder="Select Property" showSearch={true}
              onSearch={onSearch}
              filterOption={filterOption} >
              {communityOptions?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="additionalFee" label="Enter Fee in USD" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
     </>
  );
};

export default RegistrationFee;
