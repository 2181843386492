import React from "react";
import { Form, Input } from 'antd';
import PropTypes from 'prop-types'; // Import PropTypes for validation

const TextInput = (props) => {
  const { disabled, label, value, name, icon,ref, size, className, placeholder, rules, password, hasFeedback, validateTrigger, onChange } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback}
      label={label}
      name={name}
      rules={rules}
      validateTrigger={validateTrigger}
      className={className}
    >
      {password ? 
        <Input.Password onChange={(e) => onChange && onChange(e)} size={size} placeholder={placeholder} prefix={icon} /> 
        : 
        <Input disabled={disabled} onChange={onChange} value={value} ref={ref} size={size} placeholder={placeholder} prefix={icon} />
      } 
    </Form.Item>
  );
};

// Adding PropTypes validation
TextInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.array,
  password: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  validateTrigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  ref:null
};

// Default props (optional but helps avoid undefined values)
TextInput.defaultProps = {
  disabled: false,
  label: '',
  value: '',
  icon: null,
  size: 'middle',
  className: '',
  placeholder: '',
  rules: [],
  password: false,
  hasFeedback: false,
  validateTrigger: 'onChange',
  onChange: null,
  ref:null
};

export default TextInput;
