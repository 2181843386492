
import { all } from 'redux-saga/effects';
import { watchAuth } from '../sagas/Auth/Auth';
import { User,Communitys,Tenant,Discalimer,RegistrationFee } from './index';


export default function* rootSaga() {
  yield all([
    watchAuth(),
    User(),
    Communitys(),
    Tenant(),
    Discalimer(),
    RegistrationFee(),
  ]);
}
